import React from 'react'
import './whyglobal.css'

const Whyglobal = () => {
    return (
        <>
            <section className='back-img'>

                <div class="contentx">
                    <div className="containerxx">
                        <div className="divide_why_and_how">

                            <div className="how_trade">
                                <div className="common_heading">
                                    <div className="headline_text">
                                        <h3>HOW GLOBALB2BMART <span style={{ color: 'orange' }}>HELPS SUPPLIERS</span> </h3>
                                    </div>
                                </div>
                                <div className="trade_under">
                                    <div className="how_child">
                                        <img src="/assets/h4.png" width={57} height={58} alt="manage" />
                                        <h3>
                                            Effortlessly handle your profile.</h3>
                                        <p>
                                            The comprehensive seller module lets you easily track orders and manage delivery schedules for smooth operations.
                                        </p>
                                        {/* <a href="/">Know More</a> */}
                                    </div>
                                    <div className="how_child">
                                        <img src="/assets/h3.png" width={57} height={58} alt="assured" />
                                        <h3>User Friendly Interface</h3>
                                        <p>
                                            The platform is optimized for various devices, including desktops, tablets, and smartphones....
                                        </p>
                                        {/* <a href="/">Know More</a> */}
                                    </div>
                                    <div className="how_child">
                                        <img src="/assets/h2.png" width={57} height={58} alt="connect" />
                                        <h3>Connect with Global Buyer</h3>
                                        <p>
                                            Tailored promotions exclusively for registered sellers, targeting global buyers worldwide, with a focus on specific industries.
                                        </p>
                                        {/* <a href="/">Know More</a> */}
                                    </div>

                                    <div className="how_child">
                                        <img src="/assets/h1.png" width={57} height={58} alt="not lead" />
                                        <h3>Grow Your Business with Global B2B Mart:</h3>
                                        <p>
                                            Expand your reach and connect with a vast network of international buyers to boost your business growth.
                                        </p>
                                        {/* <a href="/">Know More</a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="why_trade">
                                <div className="common_heading">
                                    <div className="headline_text">
                                        <h3>WHY <span style={{ color: 'orange' }}> Globalb2b Mart</span></h3>
                                    </div>
                                </div>
                                <div className="trade_under">
                                    <div className="why_icon">
                                        <img src="/assets/w1.png" width={58} height={50} alt="Cost Effective" />
                                        <div className="why_text">
                                            <p>
                                                Cost
                                                <br /> Effective
                                            </p>
                                        </div>
                                    </div>
                                    <div className="why_icon">
                                        <img
                                            src="/assets/1p.png"
                                            width={50}
                                            height={50}
                                            alt="Modelled Around Bussiness"
                                        />
                                        <div className="why_text">
                                            <p>Designed with a business-centric approach</p>
                                        </div>
                                    </div>
                                    <div className="why_icon">
                                        <img
                                            src="/assets/w3.png"
                                            width={50}
                                            height={50}
                                            alt="Completely Transparent"
                                        />
                                        <div className="why_text">
                                            <p>Fully transparent</p>
                                        </div>
                                    </div>

                                    <div className="why_icon">
                                        <img
                                            src="/assets/w4.png"
                                            width={50}
                                            height={50}
                                            alt="Trade Across The World"
                                        />
                                        <div className="why_text">
                                            <p>Trade Across The World</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Whyglobal
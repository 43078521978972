import React, { useState, useEffect } from 'react';
import './OnionBuy.css';
import { useAuth } from './AuthContext';
import { Link } from 'react-router-dom';

const PotatoBuys = [
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },
    {
        title: 'Looking for Fresh Potatos',
        location: 'Morbi, Gujarat, India - 30 May 2024',
        buyerName: 'Sonu Singh',
        mobileNo: '+91-9899899988',
        quantity: '1 Container (20 Feet)',
        requirement: 'Urgent',
        purpose: 'Reselling',
        frequency: 'Monthly',
        onionSize: '35 mm and above',
        packagingSize: '25 kg',
        paymentMode: 'Bank Transfer',
    },
    {
        title: 'Looking for Fresh Red Potatoes',
        location: 'Kolkata, West Bengal, India - 29 May 2024',
        buyerName: 'Rohan Yadav',
        mobileNo: '+91-990909099',
        quantity: '1 Metric Ton',
        purpose: 'Reselling',
    },
    {
        title: 'Looking for Nashik Onion',
        location: 'Tiruchirappalli, Tamil Nadu, India - 27 May 2024',
        buyerName: 'Abdul Malik S',
        mobileNo: '+91-98909099',
        quantity: '2000 Kilograms',
    },

];

// const PotatoBuy = () => {
//     const { userId } = useAuth();
//     const [userIsPremium, setUserIsPremium] = useState(false); // Initialize as false, assuming user is not premium by default
//     const [shownNumbers, setShownNumbers] = useState(
//         new Array(PotatoBuys.length).fill(false) // Array of booleans for each buyer
//     );
//     useEffect(() => {
//         if (!userId) return;

//         fetch(`http://localhost:3005/getUserWithPremiumStatus/${userId}`)
//             .then(response => response.json())
//             .then(data => {
//                 setUserIsPremium(data.isPremium);
//                 console.log('Premium Status:', data.isPremium);
//             })
//             .catch(error => {
//                 console.error('Error fetching user information:', error);
//             });
//     }, [userId]);



//     const toggleNumber = (index) => {
//         setShownNumbers((prevShownNumbers) => {
//             const updatedShownNumbers = [...prevShownNumbers];
//             updatedShownNumbers[index] = !prevShownNumbers[index];
//             return updatedShownNumbers;
//         });
//     };

//     // Render contact number based on user's plan
//     return (
//         <div className="containersx">
//             <div className="buyers">
//                 {PotatoBuys.map((buyer, index) => (
//                     <div key={index} className="buyer-card">
//                         <h2>{buyer.title}</h2>
//                         <p><strong>Buyer Name:</strong> {buyer.buyerName}</p>
//                         <div style={{ display: 'flex', gap: '10px' }}>
//                             <p>
//                                 <strong>Mobile No.:</strong> {userIsPremium ? (shownNumbers[index] ? buyer.mobileNo : '********') : 'Premium feature'}
//                             </p>
//                             {userIsPremium && (
//                                 <button
//                                     className="detail-btn"
//                                     style={{
//                                         padding: '5px 10px',
//                                         color: '#000',
//                                         background: '#f7f1f1',
//                                         color: 'orange',
//                                         borderRadius: '3px',
//                                         cursor: 'pointer',
//                                     }}
//                                     onClick={() => toggleNumber(index)} // Pass index to toggle function
//                                 >
//                                     {shownNumbers[index] ? 'Hide' : 'Click to see'}
//                                 </button>
//                             )}
//                         </div>
//                         {/* ... rest of your content */}
//                     </div>
//                 ))}
//             </div>
//             <div className="sidebar">
//                 <h3>Browse by State</h3>
//                 <ul>
//                     {/* ... your state list */}
//                 </ul>
//             </div>
//         </div>
//     );
// };

// export default PotatoBuy;

// const PotatoBuy = () => {
//     const { userId } = useAuth();
//     const [userIsPremium, setUserIsPremium] = useState(false);
//     const [shownNumbers, setShownNumbers] = useState(
//         new Array(PotatoBuys.length).fill(false)
//     );
//     const [leadsViewed, setLeadsViewed] = useState(0);

//     useEffect(() => {
//         if (!userId) return;

//         fetch(`http://localhost:3005/getUserWithPremiumStatus/${userId}`)
//             .then(response => response.json())
//             .then(data => {
//                 setUserIsPremium(data.isPremium);
//                 setLeadsViewed(data.leadsViewed);
//                 console.log('Premium Status:', data.isPremium);
//             })
//             .catch(error => {
//                 console.error('Error fetching user information:', error);
//             });
//     }, [userId]);

//     const toggleNumber = async (index) => {
//         if (!userIsPremium && leadsViewed >= 25) {
//             alert('You have reached the limit of 25 leads viewed per month.');
//             return;
//         }

//         setShownNumbers((prevShownNumbers) => {
//             const updatedShownNumbers = [...prevShownNumbers];
//             updatedShownNumbers[index] = !prevShownNumbers[index];
//             return updatedShownNumbers;
//         });

//         if (!shownNumbers[index]) { // Increment only if showing the number
//             try {
//                 const response = await fetch(`http://localhost:3005/incrementLeadsViewed/${userId}`, {
//                     method: 'POST'
//                 });

//                 if (response.ok) {
//                     const data = await response.json();
//                     setLeadsViewed(data.leadsViewed);
//                 } else {
//                     const errorData = await response.json();
//                     console.error('Error incrementing leads viewed:', errorData.error);
//                 }
//             } catch (error) {
//                 console.error('Error incrementing leads viewed:', error.message);
//             }
//         }
//     };

//     return (
//         <div className="containersx">
//             <div className="buyers">
//                 {PotatoBuys.map((buyer, index) => (
//                     <div key={index} className="buyer-card">
//                         <h2>{buyer.title}</h2>
//                         <p><strong>Buyer Name:</strong> {buyer.buyerName}</p>
//                         <div style={{ display: 'flex', gap: '10px' }}>
//                             <p>
//                                 <strong>Mobile No.:</strong> {userIsPremium ? (shownNumbers[index] ? buyer.mobileNo : '********') : 'Premium feature'}
//                             </p>
//                             {userIsPremium && (
//                                 <button
//                                     className="detail-btn"
//                                     style={{
//                                         padding: '5px 10px',
//                                         color: '#000',
//                                         background: '#f7f1f1',
//                                         color: 'orange',
//                                         borderRadius: '3px',
//                                         cursor: 'pointer',
//                                     }}
//                                     onClick={() => toggleNumber(index)}
//                                 >
//                                     {shownNumbers[index] ? 'Hide' : 'Click to see'}
//                                 </button>
//                             )}
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <div className="sidebar">
//                 <h3>Browse by State</h3>
//                 <ul>
//                     {/* ... */}
//                 </ul>
//             </div>
//         </div>
//     );
// };

// export default PotatoBuy;
// const PotatoBuy = () => {
//     const { userId } = useAuth();
//     const [userIsPremium, setUserIsPremium] = useState(false);
//     const [shownNumbers, setShownNumbers] = useState(new Array(PotatoBuys.length).fill(false));
//     const [leadsViewed, setLeadsViewed] = useState(0);

//     useEffect(() => {
//         if (!userId) return;

//         fetch(`http://localhost:3005/getUserWithPremiumStatus/${userId}`)
//             .then(response => response.json())
//             .then(data => {
//                 setUserIsPremium(data.isPremium);
//                 setLeadsViewed(data.leadsViewed);
//                 console.log('Premium Status:', data.isPremium);
//             })
//             .catch(error => {
//                 console.error('Error fetching user information:', error);
//             });
//     }, [userId]);

//     const toggleNumber = async (index) => {
//         if (!userIsPremium) return; // Only premium users can see numbers

//         if (leadsViewed >= 25) {
//             alert('You have reached the limit of 25 leads viewed per month.');
//             return;
//         }

//         setShownNumbers((prevShownNumbers) => {
//             const updatedShownNumbers = [...prevShownNumbers];
//             updatedShownNumbers[index] = !prevShownNumbers[index];
//             return updatedShownNumbers;
//         });

//         if (!shownNumbers[index]) { // Increment only if showing the number
//             try {
//                 const response = await fetch(`http://localhost:3005/incrementLeadsViewed/${userId}`, {
//                     method: 'POST'
//                 });

//                 if (response.ok) {
//                     const data = await response.json();
//                     setLeadsViewed(data.leadsViewed);
//                 } else {
//                     const errorData = await response.json();
//                     console.error('Error incrementing leads viewed:', errorData.error);
//                 }
//             } catch (error) {
//                 console.error('Error incrementing leads viewed:', error.message);
//             }
//         }
//     };

//     return (
//         <div className="containersx">
//             <div className="buyers">
//                 {PotatoBuys.map((buyer, index) => (
//                     <div key={index} className="buyer-card">
//                         <h2>{buyer.title}</h2>
//                         <p><strong>Buyer Name:</strong> {buyer.buyerName}</p>
//                         <div style={{ display: 'flex', gap: '10px' }}>
//                             <p>
//                                 <strong>Mobile No.:</strong> {userIsPremium ? (shownNumbers[index] ? buyer.mobileNo : '********') : 'Premium feature'}
//                             </p>
//                             {userIsPremium && (
//                                 <button
//                                     className="detail-btn"
//                                     style={{
//                                         padding: '5px 10px',
//                                         color: '#000',
//                                         background: '#f7f1f1',
//                                         color: 'orange',
//                                         borderRadius: '3px',
//                                         cursor: 'pointer',
//                                     }}
//                                     onClick={() => toggleNumber(index)}
//                                 >
//                                     {shownNumbers[index] ? 'Hide' : 'Click to see'}
//                                 </button>
//                             )}
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <div className="sidebar">
//                 <h3>Browse by State</h3>
//                 <ul>
//                     {/* ... */}
//                 </ul>
//             </div>
//         </div>
//     );
// };
// const PotatoBuy = () => {
//     const { userId } = useAuth();
//     const [userIsPremium, setUserIsPremium] = useState(false);
//     const [shownNumbers, setShownNumbers] = useState(new Array(PotatoBuys.length).fill(false));
//     const [leadsViewed, setLeadsViewed] = useState(0);

//     useEffect(() => {
//         if (!userId) return;

//         fetch(`http://localhost:3005/getUserWithPremiumStatus/${userId}`)
//             .then(response => response.json())
//             .then(data => {
//                 setUserIsPremium(data.isPremium);
//                 setLeadsViewed(data.leadsViewed);
//                 console.log('Premium Status:', data.isPremium);
//             })
//             .catch(error => {
//                 console.error('Error fetching user information:', error);
//             });
//     }, [userId]);

//     const toggleNumber = async (index) => {
//         if (!userIsPremium) return; // Only premium users can see numbers

//         if (leadsViewed >= 25) {
//             alert('You have reached the limit of 25 leads viewed per month.');
//             return;
//         }

//         if (shownNumbers[index]) return; // If already shown, do nothing

//         setShownNumbers((prevShownNumbers) => {
//             const updatedShownNumbers = [...prevShownNumbers];
//             updatedShownNumbers[index] = true; // Mark as shown
//             return updatedShownNumbers;
//         });

//         try {
//             const response = await fetch(`http://localhost:3005/incrementLeadsViewed/${userId}`, {
//                 method: 'POST'
//             });

//             if (response.ok) {
//                 const data = await response.json();
//                 setLeadsViewed(data.leadsViewed);
//             } else {
//                 const errorData = await response.json();
//                 console.error('Error incrementing leads viewed:', errorData.error);
//             }
//         } catch (error) {
//             console.error('Error incrementing leads viewed:', error.message);
//         }
//     };

//     return (
//         <div className="containersx">
//             <div className="buyers">
//                 {PotatoBuys.map((buyer, index) => (
//                     <div key={index} className="buyer-card">
//                         <h2>{buyer.title}</h2>
//                         <p><strong>Buyer Name:</strong> {buyer.buyerName}</p>
//                         <div style={{ display: 'flex', gap: '10px' }}>
//                             <p>
//                                 <strong>Mobile No.:</strong> {userIsPremium ? (shownNumbers[index] ? buyer.mobileNo : '********') : 'Premium feature'}
//                             </p>
//                             {userIsPremium && (
//                                 <button
//                                     className="detail-btn"
//                                     style={{
//                                         padding: '5px 10px',
//                                         color: '#000',
//                                         background: '#f7f1f1',
//                                         color: 'orange',
//                                         borderRadius: '3px',
//                                         cursor: 'pointer',
//                                     }}
//                                     onClick={() => toggleNumber(index)}
//                                 >
//                                     Click to see
//                                 </button>
//                             )}
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <div className="sidebar">
//                 <h3>Browse by State</h3>
//                 <ul>
//                     {/* ... */}
//                 </ul>
//             </div>
//         </div>
//     );
// };


// const PotatoBuy = () => {
//     const { userId } = useAuth();
//     const [userIsPremium, setUserIsPremium] = useState(false);
//     const [shownNumbers, setShownNumbers] = useState([]);
//     const [leadsViewed, setLeadsViewed] = useState(0);

//     useEffect(() => {
//         if (!userId) return;

//         // Load the viewed leads from local storage
//         const storedViewedLeads = JSON.parse(localStorage.getItem(`viewedLeads_${userId}`)) || [];
//         setShownNumbers(storedViewedLeads);

//         fetch(`http://localhost:3005/getUserWithPremiumStatus/${userId}`)
//             .then(response => response.json())
//             .then(data => {
//                 setUserIsPremium(data.isPremium);
//                 setLeadsViewed(data.leadsViewed);
//                 console.log('Premium Status:', data.isPremium);
//             })
//             .catch(error => {
//                 console.error('Error fetching user information:', error);
//             });
//     }, [userId]);

//     const toggleNumber = async (index) => {
//         if (!userIsPremium) return; // Only premium users can see numbers

//         if (leadsViewed >= 25) {
//             alert('You have reached the limit of 25 leads viewed per month.');
//             return;
//         }

//         if (shownNumbers.includes(index)) return; // If already shown, do nothing

//         const updatedShownNumbers = [...shownNumbers, index];
//         setShownNumbers(updatedShownNumbers);

//         // Update local storage
//         localStorage.setItem(`viewedLeads_${userId}`, JSON.stringify(updatedShownNumbers));

//         try {
//             const response = await fetch(`http://localhost:3005/incrementLeadsViewed/${userId}`, {
//                 method: 'POST'
//             });

//             if (response.ok) {
//                 const data = await response.json();
//                 setLeadsViewed(data.leadsViewed);
//             } else {
//                 const errorData = await response.json();
//                 console.error('Error incrementing leads viewed:', errorData.error);
//             }
//         } catch (error) {
//             console.error('Error incrementing leads viewed:', error.message);
//         }
//     };

//     return (
//         <div className="containersx">
//             <div className="buyers">
//                 {PotatoBuys.map((buyer, index) => (
//                     <div key={index} className="buyer-card">
//                         <h2>{buyer.title}</h2>
//                         <p><strong>Buyer Name:</strong> {buyer.buyerName}</p>
//                         <div style={{ display: 'flex', gap: '10px' }}>
//                             <p>
//                                 <strong>Mobile No.:</strong> {userIsPremium ? (shownNumbers.includes(index) ? buyer.mobileNo : '********') : 'Premium feature'}
//                             </p>
//                             {userIsPremium && (
//                                 <button
//                                     className="detail-btn"
//                                     style={{
//                                         padding: '5px 10px',
//                                         color: '#000',
//                                         background: '#f7f1f1',
//                                         color: 'orange',
//                                         borderRadius: '3px',
//                                         cursor: 'pointer',
//                                     }}
//                                     onClick={() => toggleNumber(index)}
//                                 >
//                                     Click to see
//                                 </button>
//                             )}
//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <div className="sidebar">
//                 <h3>Browse by State</h3>
//                 <ul>
//                     {/* ... */}
//                 </ul>
//             </div>
//         </div>
//     );
// };
const PotatoBuy = () => {
    const { userId } = useAuth();
    const [userIsPremium, setUserIsPremium] = useState(false);
    const [shownNumbers, setShownNumbers] = useState([]);
    const [leadsViewed, setLeadsViewed] = useState(0);

    useEffect(() => {
        if (!userId) return;

        // Load the viewed leads from local storage
        const storedViewedLeads = JSON.parse(localStorage.getItem(`viewedLeads_${userId}`)) || [];
        setShownNumbers(storedViewedLeads);

        // fetch(`http://localhost:3005/getUserWithPremiumStatus/${userId}`)
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`)
            .then(response => response.json())
            .then(data => {
                setUserIsPremium(data.isPremium);
                setLeadsViewed(data.leadsViewed);
                console.log('Premium Status:', data.isPremium);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, [userId]);

    // const toggleNumber = async (index) => {
    //     if (!userIsPremium) return; // Only premium users can see numbers

    //     if (leadsViewed >= 25) {
    //         alert('You have reached the limit of 25 leads viewed per month.');
    //         return;
    //     }

    //     if (shownNumbers.includes(index)) return; // If already shown, do nothing

    //     const updatedShownNumbers = [...shownNumbers, index];
    //     setShownNumbers(updatedShownNumbers);

    //     // Update local storage
    //     localStorage.setItem(`viewedLeads_${userId}`, JSON.stringify(updatedShownNumbers));

    //     try {
    //         const response = await fetch(`http://localhost:3005/incrementLeadsViewed/${userId}`, {
    //             method: 'POST'
    //         });

    //         if (response.ok) {
    //             const data = await response.json();
    //             setLeadsViewed(data.leadsViewed);
    //         } else {
    //             const errorData = await response.json();
    //             console.error('Error incrementing leads viewed:', errorData.error);
    //         }
    //     } catch (error) {
    //         console.error('Error incrementing leads viewed:', error.message);
    //     }
    // };
    const toggleNumber = async (index) => {
        if (!userIsPremium) return; // Only premium users can see numbers

        if (leadsViewed >= 25) {
            alert('You have reached the limit of 25 leads viewed per month.');
            return;
        }

        if (shownNumbers.includes(index)) return; // If already shown, do nothing

        const updatedShownNumbers = [...shownNumbers, index];
        setShownNumbers(updatedShownNumbers);

        // Update local storage
        localStorage.setItem(`viewedLeads_${userId}`, JSON.stringify(updatedShownNumbers));

        const buyer = PotatoBuys[index]; // Get the buyer information

        try {
            // const response = await fetch(`http://localhost:3005/incrementLeadsViewed/${userId}`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({ buyerName: buyer.buyerName, mobileNo: buyer.mobileNo })
            // });
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ buyerName: buyer.buyerName, mobileNo: buyer.mobileNo })
            });


            if (response.ok) {
                const data = await response.json();
                setLeadsViewed(data.leadsViewed);
            } else {
                const errorData = await response.json();
                console.error('Error incrementing leads viewed:', errorData.error);
            }
        } catch (error) {
            console.error('Error incrementing leads viewed:', error.message);
        }
    };

    return (
        <div className="containersx">
            <div className="buyers">
                {PotatoBuys.map((buyer, index) => (
                    <div key={index} className="buyer-card">
                        <h2>{buyer.title}</h2>
                        <p><strong>Buyer Name:</strong> {buyer.buyerName}</p>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <p>
                                <strong>Mobile No.:</strong> {userIsPremium ? (shownNumbers.includes(index) ? buyer.mobileNo : '********') : 'Premium feature'}
                            </p>
                            {userIsPremium && (
                                <button
                                    className="detail-btn"
                                    style={{
                                        padding: '5px 10px',
                                        color: '#000',
                                        background: '#f7f1f1',
                                        color: 'orange',
                                        borderRadius: '3px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => toggleNumber(index)}
                                >
                                    Click to see
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className="sidebar">
                <h3>Browse by State</h3>
                <ul>
                    {/* ... */}
                </ul>
            </div>
        </div>
    );
};


export default PotatoBuy;
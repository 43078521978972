import React from 'react';
import './commn.css';

const Gifts = () => {
    const products = [

        {
            title: 'Wooden Handicrafts',
            imageSrc: './assets/gi2.jpeg',
            links: [
                { name: 'Wooden Storage Boxes', url: '#' },
                { name: 'Wooden Miniatures', url: '#' },
                { name: 'Wooden Sculptures', url: '#' },
                { name: 'Decorative Wooden Artifacts', url: '#' },
            ],
        },
        {
            title: 'Religious Statue',
            imageSrc: './assets/gi3.jpeg',
            links: [
                { name: 'Radha Krishna Sculpture', url: '#' },
                { name: 'Buddha Figurine', url: '#' },
                { name: 'Lord Shiva Statue', url: '#' },
                { name: 'God Father Statue', url: '#' },

            ],
        },
        {
            title: 'Metal Handicrafts',
            imageSrc: './assets/gi4.jpeg',
            links: [
                { name: 'Metallic Table Lamps', url: '#' },
                { name: 'Metal Storage Boxes', url: '#' },
                { name: 'Metallic Gifts', url: '#' },
                { name: 'Metal Decorative Balls', url: '#' },
            ],
        },
        {
            title: 'Acrylic Crafts',
            imageSrc: './assets/gi1.jpeg',
            alt: 'Acrylic Crafts',
            links: [
                { name: 'Transparent Board', url: '#' },
                { name: 'Acrylic Letters', url: '#' },
                { name: 'Clear Acrylic Boxes', url: '#' },
                { name: 'Acrylic Photo Frame', url: '#' },
            ],
        },
        {
            title: 'Greeting Cards',
            imageSrc: './assets/gi5.jpeg',
            links: [
                { name: 'Anniversary Card', url: '#' },
                { name: 'Thank You Card', url: '#' },
                { name: 'Holiday Greeting Card', url: '#' },
                { name: 'Custom Greeting Cards', url: '#' },
            ],
        },
        {
            title: 'Gifts',
            imageSrc: './assets/gi6.jpeg',
            links: [
                { name: 'Gift Hampers', url: '#' },
                { name: 'Personalized Gifts', url: '#' },
                { name: 'Gift Vouchers', url: '#' },
                { name: 'Luxury Gifts', url: '#' },
            ],
        },
        {
            title: 'Clocks',
            imageSrc: './assets/gi7.jpeg',
            links: [
                { name: 'Digital Clock', url: '#' },
                { name: 'Desk Clocks', url: '#' },
                { name: 'Grandfather Clocks', url: '#' },
                { name: 'Mantel Clocks', url: '#' },
            ],
        },
        {
            title: 'Candles Crafts',
            imageSrc: './assets/gi8.jpeg',
            links: [
                { name: 'Tealight Candles', url: '#' },
                { name: 'Scented Candles', url: '#' },
                { name: 'Decorative Candles', url: '#' },
                { name: 'Taper Candles', url: '#' },
            ],
        },
        {
            title: 'Bead Crafts',
            imageSrc: './assets/gi9.jpeg',
            links: [
                { name: 'Glass Beads', url: '#' },
                { name: 'Seed Beads', url: '#' },
                { name: 'Crystal Beads', url: '#' },
                { name: 'Wooden Beads', url: '#' },
            ],
        },
        {
            title: 'Antiques & Collectibles',
            imageSrc: './assets/gi10.jpeg',
            links: [
                { name: 'Vintage Watches', url: '#' },
                { name: 'Antique Furniture', url: '#' },
                { name: 'Rare Stamps', url: '#' },
                { name: 'Old Paintings', url: '#' },
            ],
        },
        {
            title: 'Balloons',
            imageSrc: './assets/gi11.jpeg',
            links: [
                { name: 'Birthday Balloons', url: '#' },
                { name: 'Helium Balloons', url: '#' },
                { name: 'Foil Balloons', url: '#' },
                { name: 'Water Balloons', url: '#' },
            ],
        },
        {
            title: 'Bone, Horn & Shell Crafts',
            imageSrc: './assets/gi12.jpeg',
            links: [
                { name: 'Bone Jewelry', url: '#' },
                { name: 'Horn Handicrafts', url: '#' },
                { name: 'Shell Decorations', url: '#' },
                { name: 'Horn Buttons', url: '#' },
            ],
        }


    ];

    return (
        <>
            <div className="pagetitle">
                <h1>Gifts & Crafts Trade Directory & Networking Platform</h1>
            </div>
            <div className="l1-section">
                <div className="container">
                    <div className="row l1-box-wrap">
                        {products.map((product, index) => (
                            <div key={index} className="col-4">
                                <div className="l1-box">
                                    <h2 className="l1-heading">
                                        <a href="#">{product.title}</a>
                                    </h2>
                                    <div className="d-flex">
                                        <div>
                                            <link rel="preload" as="image" href="#" />
                                            <figure className="cp" onclick="#">
                                                <img
                                                    src={product.imageSrc}
                                                    alt={product.alt}
                                                    width={95}
                                                    height={95}
                                                    loading="lazy"
                                                />
                                            </figure>
                                        </div>
                                        <div>
                                            <ul>
                                                {product.links.map((link, index) => (
                                                    <li key={index}>
                                                        <a href={link.url}>{link.name}</a>
                                                    </li>
                                                ))}
                                                <li>
                                                    <a href="#">View More</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="content-page">
                <p style={{ padding: '15px' }}>
                    GlobalB2BMart is the leading Agriculture B2B Marketplace and Agriculture Business Directory, offering comprehensive business listings of Agriculture Suppliers, Manufacturers, Exporters, Wholesalers, and Traders worldwide. Our extensive and verified database includes detailed information about Agriculture Companies, a diverse catalog of Agriculture Products, and up-to-date Agriculture Price Lists and Quotations.
                </p>
            </div>
        </>
    );
};

export default Gifts;

import React from 'react';
import { Link } from 'react-router-dom';
import './common.css';

const classifiedData = [
    {
        "name": "Soma Sundaram",
        "email": "sakthitex23@gmail.com",
        "mobileNumber": "8447255579",
        "companyName": "Sakthi Tex",
        "productOrService": "Sarees",
        "imgSrc": "/assets/saree1.jpg",  // Provide the correct image path
        "altText": "Sarees - Sakthi Tex",
        "mainProducts": "Silk Sarees, Cotton Sarees, Designer Sarees",
        "years": "1 YRS",
        "location": "Chinnalapatti Tamil Nadu, India",
        "tooltipText": "456 Saree Lane, Textile District, Kolkata, India",
        "rating": "4.7",
        "ratingPercent": "94%",
        "ratingsCount": "120",
        "responseRate": "92%",
        "path": '/sakthi-tex'
    },
    {
        "_id": "b1234567890nobaraa-fashion",
        "name": "Nobaraa Fashion",
        "email": "nasreenyasin83@gmail.com",
        "password": "nasreen@123",
        "mobileNumber": "+91 70105 54775",
        "companyName": "Nobaraa Fashion",
        "productOrService": "Sarees",
        "imgSrc": "/assets/nob3.webp",
        "mainProducts": "Sarees, Traditional Wear",
        "altText": "Nobaraa Fashion - Sarees and Traditional Wear",
        "years": "1 YRS",
        "location": "Salem, Tamil Nadu, India - 637101",
        "tooltipText": "Nobaraa Fashion, Salem, Tamil Nadu",
        "rating": "4.7",
        "ratingPercent": "92%",
        "ratingsCount": "45",
        "responseRate": "88%",
        "path": "/nobaraa-fashion"

    },

    {
        "name": "Mrs Sadhana",
        "email": "sadhana241054@gmail.com",
        "mobileNumber": "7011860634",
        "companyName": "SIDDHI COLLECTION",
        "productOrService": "Chanderi Saree",
        "imgSrc": "/assets/saree2.jpg",
        "altText": "Chanderi Saree - SIDDHI COLLECTION",
        "mainProducts": "Chanderi Sarees, Silk Sarees, Handloom Sarees",
        "years": "1 YRS",
        "location": "Madhya Pradesh, India",
        "tooltipText": "456 Saree Street, Weave Town, Madhya Pradesh, India",
        "rating": "4.6",
        "ratingPercent": "92%",
        "ratingsCount": "320",
        "responseRate": "88%"
    },
    {
        "_id": "c123456789012abcdef0123", // Random unique ID
        "name": "Subhanath Patra",
        "email": "Patrafashion@gmail.com",
        "password": "Subhanath Patra", // Including the password as specified
        "mobileNumber": "6294950556",
        "companyName": "Patra Fashion",
        "productOrService": "Saree",
        "imgSrc": "/assets/saree6.jpeg",  // Example image path
        "mainProducts": "Silk Sarees, Cotton Sarees, Designer Sarees",
        "altText": "Saree - Patra Fashion",
        "years": "1 YRS",
        "location": "Kolkata, India",
        "tooltipText": "789 Fashion Street, Kolkata, India",
        "rating": "4.5",
        "ratingPercent": "90%",
        "ratingsCount": "75",
        "responseRate": "92%",

    },

    {
        name: "Arun Bera",
        email: "arun123123@gmail.com",
        mobileNumber: "8168636892",
        companyName: "Rupali Bengal Textile",
        productOrService: "Ladies Saree",
        imgSrc: "/assets/saree.jpeg",  // Provide the correct image path
        altText: "Ladies Saree - Rupali Bengal Textile",
        mainProducts: "Silk Sarees, Cotton Sarees, Designer Sarees",
        years: "1 YRS",
        location: "Kolkata, India",
        tooltipText: "456 Saree Lane, Textile District, Kolkata, India",
        rating: "4.7",
        ratingPercent: "94%",
        ratingsCount: "120",
        responseRate: "92%"
    },
    {
        name: "Shiv Shahi Paithani",
        email: "shishahipaithani@gmail.com",
        mobileNumber: "9665217917",
        companyName: "Shiv Shahi",
        productOrService: "Sarees",
        imgSrc: "/assets/saree15.jpeg",  // Placeholder for image path
        altText: "Shiv Shahi - Sarees Collection",
        mainProducts: "Paithani Sarees, Silk Sarees, Traditional Wear",
        years: "1 YRS",
        location: "India",
        tooltipText: "Shiv Shahi, India",
        rating: "4.5",
        ratingPercent: "90%",
        ratingsCount: "52",
        responseRate: "83%",
        whatsappConfirmed: true
    },
    {
        "name": "Ashish",
        "email": "basutextile@gmail.com",
        "mobileNumber": "9832155429",
        "companyName": "M/S Basu Textile",
        "productOrService": "Textile, Sarees",
        "imgSrc": "/assets/saree5.jpeg",
        "altText": "Textile and Sarees - M/S Basu Textile",
        "mainProducts": "Textiles, Sarees, Fabrics, Ethnic Wear",
        "years": "1 YRS",
        "location": "Kolkata, India",
        "tooltipText": "789 Fabric Lane, Textile Hub, Kolkata, India",
        "rating": "4.6",
        "ratingPercent": "92%",
        "ratingsCount": "350",
        "responseRate": "90%"
    },

    {
        "_id": "s234567890abcdef123456", // Random unique ID
        "name": "Mohammad Atif",
        "email": "mohdatifmkp@gmail.com",
        "mobileNumber": "9554479529",
        "companyName": "Haffan Silk",
        "productOrService": "Banarsi Silk Saree",
        "imgSrc": "/assets/saree7.jpeg",  // Example image path
        "mainProducts": "Banarsi Silk Sarees, Designer Sarees",
        "altText": "Banarsi Silk Saree - Haffan Silk",
        "years": "1 YRS",
        "location": "Varanasi, India",
        "tooltipText": "Shop No. 15, Silk Market, Varanasi, India",
        "rating": "4.8",
        "ratingPercent": "96%",
        "ratingsCount": "70",
        "responseRate": "97%",
        "whatsappConfirmed": true
    },
    {
        "_id": "s345678901abcdef234567", // Random unique ID
        "name": "Rocky Kumar",
        "email": "mannathandloom@gmail.com",
        "mobileNumber": "8670562617",
        "companyName": "Mannat Handloom",
        "productOrService": "Saree, Dupatta",
        "imgSrc": "/assets/saree8.webp",  // Example image path
        "mainProducts": "Sarees, Dupattas, Handloom Fabrics",
        "altText": "Saree & Dupatta - Mannat Handloom",
        "years": "1 YRS",
        "location": "Kolkata, India",
        "tooltipText": "Shop 45, Handloom Market, Kolkata, India",
        "rating": "4.4",
        "ratingPercent": "88%",
        "ratingsCount": "45",
        "responseRate": "85%",
        "whatsappConfirmed": true
    },
    {
        "_id": "h8905370168i012356",
        "name": "Rehan",
        "email": "rehan123@gmail.com",
        "password": "rehan1234",
        "mobileNumber": "9305415054",
        "companyName": "Nisa Fabric",
        "productOrService": "Sari",
        "imgSrc": "/assets/saree9.jpeg",
        "mainProducts": "Silk Saris, Cotton Saris, Designer Saris",
        "altText": "Saris - Nisa Fabric",
        "years": "1 YRS",
        "location": "Varanasi, Uttar Pradesh, India",
        "tooltipText": "Lanka, Varanasi, Uttar Pradesh, India",
        "rating": "4.6",
        "ratingPercent": "93%",
        "ratingsCount": "85",
        "responseRate": "89%",
        "whatsappConfirmed": true
    },
    {
        "_id": "g5678901234abc7890",
        "name": "Mahendra",
        "email": "abc@gmail.com",
        "password": "Mahendra@123",
        "mobileNumber": "9377297791",
        "companyName": "Mokshit Drapes",
        "productOrService": "Curtains & Ladies Blouse",
        "imgSrc": "/assets/blouse.jpeg",  // Placeholder for image path
        "mainProducts": "Curtains, Ladies Blouse, Drapery Fabric",
        "altText": "Mokshit Drapes - Curtains & Ladies Blouse",
        "years": "1 YRS",  // Placeholder for years of experience
        "location": "Ahmedabad, Gujarat, India",  // Placeholder for location
        "tooltipText": "Mokshit Drapes, Ahmedabad",
        "rating": "4.7",  // Placeholder for rating
        "ratingPercent": "94%",  // Placeholder for rating percentage
        "ratingsCount": "110",  // Placeholder for ratings count
        "responseRate": "89%",  // Placeholder for response rate
        "whatsappConfirmed": true
    },
    {
        "_id": "h6789012345def8901",
        "name": "Lavish",
        "email": "Lavish123@gmail.com",
        "password": "Lavish1234",
        "mobileNumber": "8955766257",
        "companyName": "Lavish Ganwani",
        "productOrService": "Saree",
        "imgSrc": "/assets/saree11.jpeg",  // Placeholder for image path
        "mainProducts": "Sarees, Designer Sarees, Silk Sarees",
        "altText": "Lavish Ganwani - Saree Collection",
        "years": "1 YRS",  // Placeholder for years of experience
        "location": "Surat, Gujarat, India",  // Placeholder for location
        "tooltipText": "Lavish Ganwani, Surat",
        "rating": "4.6",  // Placeholder for rating
        "ratingPercent": "92%",  // Placeholder for rating percentage
        "ratingsCount": "95",  // Placeholder for ratings count
        "responseRate": "87%",  // Placeholder for response rate
        "whatsappConfirmed": true
    },
    {
        "_id": "s2345678901abc6789",
        "name": "Sonu",
        "email": "sonu123@gmail.com",
        "password": "sonu1234",
        "mobileNumber": "7007245901",
        "companyName": "B M Artss Silk",
        "productOrService": "Saree",
        "imgSrc": "/assets/saree12.jpeg",  // Placeholder for image path
        "mainProducts": "Silk Sarees, Handloom Sarees, Designer Sarees",
        "altText": "B M Artss Silk - Silk Saree Collection",
        "years": "6 YRS",  // Placeholder for years of experience
        "location": "Varanasi, Uttar Pradesh, India",  // Placeholder for location
        "tooltipText": "B M Artss Silk, Varanasi",
        "rating": "4.7",  // Placeholder for rating
        "ratingPercent": "94%",  // Placeholder for rating percentage
        "ratingsCount": "85",  // Placeholder for ratings count
        "responseRate": "88%",  // Placeholder for response rate
        "whatsappConfirmed": true
    },
    {
        "_id": "g1234567890def5678",
        "name": "Rubina Mandal",
        "email": "rubina123@gmail.com",
        "password": "rubina123",
        "mobileNumber": "7501445734",
        "companyName": "Bengal Saree House",
        "productOrService": "Sarees",
        "imgSrc": "/assets/bengal-saree.jpeg",  // Placeholder for image path
        "mainProducts": "Traditional Sarees, Designer Sarees, Silk Sarees",
        "altText": "Bengal Saree House - Sarees",
        "years": "1 YRS",  // Placeholder for years of experience
        "location": "India",  // Placeholder for location
        "tooltipText": "Bengal Saree House, India",
        "rating": "4.6",  // Placeholder for rating
        "ratingPercent": "92%",  // Placeholder for rating percentage
        "ratingsCount": "80",  // Placeholder for ratings count
        "responseRate": "88%",  // Placeholder for response rate
        "whatsappConfirmed": true
    },
    {
        "_id": "i1234567890def6789",
        "name": "Javed Ansari",
        "email": "Javed123@gmail.com",
        "password": "Javed1234",
        "mobileNumber": "6387941255",
        "companyName": "Banarasi Saree Collection",
        "productOrService": "Saree",
        "imgSrc": "/assets/saree13.jpeg",  // Placeholder for image path
        "mainProducts": "Banarasi Sarees, Traditional Sarees",
        "altText": "Banarasi Saree Collection - Saree",
        "years": "1 YRS",  // Placeholder for years of experience
        "location": "India",  // Placeholder for location
        "tooltipText": "Banarasi Saree Collection, India",
        "rating": "4.6",  // Placeholder for rating
        "ratingPercent": "92%",  // Placeholder for rating percentage
        "ratingsCount": "80",  // Placeholder for ratings count
        "responseRate": "85%",  // Placeholder for response rate
        "whatsappConfirmed": true
    },
    {
        "_id": "k1239874567bcdef3456",
        "name": "Shiv Shahi Paithani",
        "email": "shishahipaithani@gmail.com",
        "password": "8375000867",
        "mobileNumber": "9665217917",
        "companyName": "Shiv Shahi",
        "productOrService": "Sarees",
        "imgSrc": "/assets/saree15.jpeg",
        "mainProducts": "Paithani Sarees, Silk Sarees, Traditional Wear",
        "altText": "Shiv Shahi - Sarees Collection",
        "years": "1 YRS",
        "location": "India",
        "tooltipText": "Shiv Shahi, India",
        "rating": "4.5",
        "ratingPercent": "90%",
        "ratingsCount": "52",
        "responseRate": "83%",
        "whatsappConfirmed": true
    },
    {
        _id: "r2s3t4u5v6w7x8y9z0a1b2c3",  // Random unique ID
        name: "Shilpa",
        email: "mail@dharohars.com",
        mobileNumber: "8103053680",
        companyName: "Dharohar Blocks & Prints",
        productOrService: "Hand Block Saree Wholesaler",
        imgSrc: "/assets/saree16.jpeg",  // Example image path
        mainProducts: "Hand Block Printed Sarees, Traditional Sarees, Designer Sarees, Handcrafted Fabrics",
        altText: "Hand Block Sarees - Dharohar Blocks & Prints",
        years: "1 YRS",
        location: "Jaipur, India",
        tooltipText: "34 Saree Street, Jaipur, India",
        rating: "4.7",
        ratingPercent: "94%",
        ratingsCount: "70",
        responseRate: "95%",
        whatsappConfirmed: true
    }

    // Add more items if needed
];

const Sarees = () => {
    return (
        <div className="main-box">
            <aside>
                <div className="flt-box-wrap">
                    <div className="flt-box mb-0 flt-head">Filters By</div>
                    <div className="flt-box bdrt-0">
                        <p className="flt-title">Related Categories</p>
                        <div className="flt-content">
                            <ul className="flt-list cust-scroll">
                                <li><Link to="#">Paithani Sarees</Link></li>
                                <li><Link to="#">Silk Sarees</Link></li>
                                <li><Link to="#">Traditional Wear</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="flt-box">
                        <p className="flt-title">By Location</p>
                        <div className="flt-content">
                            <div className="flt-search">
                                <input type="text" name="location_id" placeholder="Search Location" id="location-search-input" />
                            </div>
                            <ul className="flt-list cust-scroll" id="location-lists">
                                <li><Link to="#">All India</Link></li>
                                <li><Link to="#">Maharashtra</Link></li>
                                <li><Link to="#">Karnataka</Link></li>
                                <li><Link to="#">Tamil Nadu</Link></li>
                                <li><Link to="#">Gujarat</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </aside>
            <main>
                <section className="section">
                    <p className="sect-title">Explore by Categories</p>
                    <div className="horprd expcatg" id="expcatg">
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/sareep.jpeg" width={55} height={55} alt="Paithani Sarees" />
                                    </figure>
                                    <p className="title">Paithani Sarees</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/saree2sil.jpeg" width={55} height={55} alt="Silk Sarees" />
                                    </figure>
                                    <p className="title">Silk Sarees</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/saree14.jpeg" width={55} height={55} alt="Traditional Wear" />
                                    </figure>
                                    <p className="title">Traditional Wear</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
                <ul className="classfied-wrap">
                    {classifiedData.map((item, index) => (
                        <li key={index}>
                            <div className="classified">
                                <div className="prd-info">
                                    <div className="prd-box">
                                        <img src={item.imgSrc} alt={item.altText} width={250} height={250} />
                                    </div>
                                </div>
                                <div className="cinfo">
                                    <div className="cbox">
                                        <figure>
                                            <span className="cmp-year">{item.years}</span>
                                        </figure>
                                        <div className="cboxr">
                                            <Link to={item.path}><h4 className="title">{item.companyName}</h4></Link>
                                            <p className="cloc tooltip ellipsis">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi-location" viewBox="0 0 16 16">
                                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                </svg>
                                                {item.location}
                                                <span className="tooltiptext">{item.tooltipText}</span>
                                            </p>
                                            <div className="rating-wrap">
                                                <span className="rtbox">{item.rating}</span>
                                                <span className="crate" style={{ "--_score": item.ratingPercent }} />
                                                <span className="rate-text">{item.ratingsCount} Ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="caddit">
                                        <div className="item">
                                            <div className="ca-box modal-btn" data-modal="motc" data-src={item.trustCertificateUrl}>
                                                <p><i className="l3icon motc-icon" /></p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="ca-box">
                                                <p><i className="l3icon resp-icon" /></p>
                                                <p><span>Response Rate</span> <b>{item.responseRate}</b></p>
                                            </div>
                                            <p><span>Main Products</span> <b>{item.mainProducts}</b></p>
                                        </div>
                                    </div>
                                    <div className="caction">
                                        <Link to={'/register-buyer'} >
                                            <p>Contact Supplier</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </main>
        </div>
    );
}

export default Sarees;

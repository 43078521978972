import React from "react";
import { Link } from "react-router-dom";
import CompanyForm from "./Companyform"; // Ensure this import path is correct
import './onioncomp.css'; // Adjust the CSS file path as necessary

const AlphaPipingSystemComp = () => {
    // Company data for ALPHA PIPING SYSTEM PVT.LTD.
    const companyData = {
        name: "Mufaddal Kanchwala",
        email: "abc@gmail.com",
        mobileNumber: "7721818776",
        companyName: "ALPHA PIPING SYSTEM PVT.LTD.",
        productOrService: "Pipes & Fittings",
        productDescribe: "ALPHA PIPING SYSTEM PVT.LTD. specializes in providing a comprehensive range of high-quality pipes and fittings. We offer innovative solutions designed to meet the needs of various industries. Our products are manufactured to ensure durability and performance, catering to both standard and custom requirements.",
        businessType: "Manufacturer",
        mainProducts: "Pipes & Fittings",
        logoUrl: "/assets/alpha-piping-system-logo.jpeg", // Example path to company logo image
    };

    return (
        <>
            <div className="container">
                <ol className="breadcrumb">
                    <li style={{ display: 'flex', justifyContent: 'center', margin: '5px 30px' }} className="breadcrumb-item">
                        <Link to={'/'}>Home</Link>
                    </li>
                    <li style={{ display: 'flex', justifyContent: 'center', margin: '5px -25px' }} >/</li>
                    <li style={{ display: 'flex', justifyContent: 'center', margin: '5px 30px' }} className="breadcrumb-item active">Search</li>
                </ol>
            </div>
            <div className="b2b-page">
                <div className="content-left">
                    {/* Display ALPHA PIPING SYSTEM PVT.LTD. information */}
                    <div className="company-card">
                        <div className="company-header">
                            <div className="company-logo">
                                <img src={companyData.logoUrl} alt="Company Logo" />
                            </div>
                            <div className="company-details">
                                <h3>{companyData.companyName}</h3>
                                <p><strong>Name:</strong> {companyData.name}</p>
                                <p><strong>Description:</strong> {companyData.productDescribe}</p>
                                <div className="imp-btns">
                                    <button style={{ backgroundColor: '#4ee54e' }} className="main-btns">
                                        <Link to={'/register-buyer'}>Connect Now</Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="company-description">
                            <p><strong>Business Type:</strong> {companyData.businessType}</p>
                            <p><strong>Main Products:</strong> {companyData.mainProducts}</p>
                        </div>
                    </div>
                </div>
                <CompanyForm />
            </div>
        </>
    );
}

export default AlphaPipingSystemComp;

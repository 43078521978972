import React from "react";
import { Link } from "react-router-dom";
import CompanyForm from "./Companyform"; // Ensure this import path is correct
import './onioncomp.css'; // Adjust the CSS file path as necessary

const MasumanEnviroEngineeringComp = () => {
    // Company data for Masuman Enviro Engineering Company
    const companyData = {
        name: "Danish Malik",
        email: "masumanenvirohpr@gmail.com",
        mobileNumber: "9259552670",
        companyName: "Masuman Enviro Engineering Company",
        productOrService: "Steel Tank Manufacturer",
        productDescribe: "Masuman Enviro Engineering Company specializes in manufacturing high-quality steel tanks. Our tanks are designed for durability and efficiency, catering to various industrial needs. We provide customized solutions to meet specific requirements, ensuring the highest standards of performance and reliability.",
        businessType: "Manufacturer",
        mainProducts: "Steel Tanks",
        logoUrl: "/assets/silvertank.jpeg", // Example path to company logo image
    };

    return (
        <>
            <div className="container">
                <ol className="breadcrumb">
                    <li style={{ display: 'flex', justifyContent: 'center', margin: '5px 30px' }} className="breadcrumb-item">
                        <Link to={'/'}>Home</Link>
                    </li>
                    <li style={{ display: 'flex', justifyContent: 'center', margin: '5px -25px' }} >/</li>
                    <li style={{ display: 'flex', justifyContent: 'center', margin: '5px 30px' }} className="breadcrumb-item active">Search</li>
                </ol>
            </div>
            <div className="b2b-page">
                <div className="content-left">
                    {/* Display Masuman Enviro Engineering Company information */}
                    <div className="company-card">
                        <div className="company-header">
                            <div className="company-logo">
                                <img src={companyData.logoUrl} alt="Company Logo" />
                            </div>
                            <div className="company-details">
                                <h3>{companyData.companyName}</h3>
                                <p><strong>Name:</strong> {companyData.name}</p>
                                <p><strong>Description:</strong> {companyData.productDescribe}</p>
                                <div className="imp-btns">
                                    <button style={{ backgroundColor: '#4ee54e' }} className="main-btns">
                                        <Link to={'/register-buyer'}>Connect Now</Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="company-description">
                            <p><strong>Business Type:</strong> {companyData.businessType}</p>
                            <p><strong>Main Products:</strong> {companyData.mainProducts}</p>
                        </div>
                    </div>
                </div>
                <CompanyForm />
            </div>
        </>
    );
}

export default MasumanEnviroEngineeringComp;

import React from 'react'
import './commn.css'
const Chemicals = () => {
    return (
        <>
            <div class="pagetitle">
                <h1>Chemical Commerce Directory & Trade Hub
                </h1>
            </div>
            <div className="l1-section">
                <div className="container">
                    <div className="row l1-box-wrap">
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">
                                        Textile Chemicals
                                    </a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/textile-chemicals.jpg"
                                            alt="Textile Chemicals"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Rayon
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Biological Catalysts
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Fabric Chemical
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Fabric Dyes
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Resin</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/resin.jpg"
                                            alt="Resin"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Polyurethane Resins
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Acrylic Resins
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Epoxy Coatings
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Phenolic Resins
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Chemical Catalysts and Absorbents</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <link
                                            rel="preload"
                                            as="image"
                                            href="#"
                                        />
                                        <figure
                                            className="cp"
                                            onclick="#"
                                        >
                                            <img
                                                src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/catalysts-and-absorbents.jpg"
                                                alt="Chemical Catalysts and Absorbents"
                                                width={95}
                                                height={95}
                                                loading="lazy"
                                            />
                                        </figure>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Titanium
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Palladium
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Zeolite
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Alumina
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Chemical Compounds</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <link
                                            rel="preload"
                                            as="image"
                                            href="#"
                                        />
                                        <figure
                                            className="cp"
                                            onclick="#"
                                        >
                                            <img
                                                src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/chemical-compounds.jpg"
                                                alt="Chemical Compounds"
                                                width={95}
                                                height={95}
                                                loading="lazy"
                                            />
                                        </figure>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Magnesium Chloride
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Potassium Permanganate
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Calcium Carbonate
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Sodium Bicarbonate
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Organic Chemical</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/organic-chemical.jpg"
                                            alt="Organic Chemical"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Methanol
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Ethylene Glycol
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Acetic Acid
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Citric Acid
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">

                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Chemical Equipment</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <link
                                            rel="preload"
                                            as="image"
                                            href="#"
                                        />
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/chemical-equipment.jpg"
                                            alt="Chemical Equipment"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Industrial Reactors
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Pressure Tanks
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Gas Cylinders
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Chemical Storage Tanks
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Chemical Machinery</a>
                                </h2>
                                <div className="d-flex">
                                    <div>

                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/chemical-machinery.jpg"
                                            alt="Chemical Machinery"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />

                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Industrial Presses
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Wastewater Treatment Systems
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Chemical Processing Plants
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Distillation Equipment
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Cleaning Chemicals</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <link
                                            rel="preload"
                                            as="image"
                                            href="#"
                                        />
                                        <figure
                                            className="cp"
                                            onclick="#"
                                        >
                                            <img
                                                src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/cleaning-chemicals.jpg"
                                                alt="Cleaning Chemicals"
                                                width={95}
                                                height={95}
                                                loading="lazy"
                                            />
                                        </figure>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    All-Purpose Cleaner
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Lime Scale Remover
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Disinfectants
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Bleaching Agents
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Commodity Chemicals</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <link
                                            rel="preload"
                                            as="image"
                                            href="#"
                                        />

                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/commodity-chemicals.jpg"
                                            alt="Commodity Chemicals"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />

                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Industrial Salt
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Methanol
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Hydrochloric Acid
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Propylene
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Dyes &amp; Pigments</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/dyes-pigments.jpg"
                                            alt="Dyes &amp; Pigments"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Color Pigments
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Reactive Colorants
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Acidic Pigments
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Direct Color Dyes
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Explosives</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/explosives.jpg"
                                            alt="Explosives"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Dynamite
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Pyrotechnics
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Nitroglycerin
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Gunpowder
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Industrial Chemicals</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/industrial-chemicals.jpg"
                                            alt="Industrial Chemicals"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Sodium Carbonate
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Barium Sulfate
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Ammonium Chloride
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Potassium Hydroxide
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Inorganic Chemical</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/inorganic-chemical.jpg"
                                            alt="Inorganic Chemical"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Sodium Nitrate
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Potassium Carbonate
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Magnesium Oxide
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Aluminum Hydroxide
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Chemical Catalysts and Absorbents</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <link
                                            rel="preload"
                                            as="image"
                                            href="#"
                                        />
                                        <figure
                                            className="cp"
                                            onclick="#"
                                        >
                                            <img
                                                src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/catalysts-and-absorbents.jpg"
                                                alt="Chemical Catalysts and Absorbents"
                                                width={95}
                                                height={95}
                                                loading="lazy"
                                            />
                                        </figure>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Titanium
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Palladium
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Zeolite
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Alumina
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Paint & Coating Chemical</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/paint-coating-chemical.jpg"
                                            alt="Paint & Coating Chemical"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Polyurethane
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Primer
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Varnish
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Pigments
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Pesticides, Insecticides and Herbicides</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/pesticides-insecticides.jpg"
                                            alt="Pesticides, Insecticides and Herbicides"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Pest Control
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Weed Killers
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Plant Fungicides
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Crop Protection Chemicals
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Petrochemicals</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/petrochemicals.jpg"
                                            alt="Petrochemicals"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Gasoline
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Asphalt
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Wax Products
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Biofuel
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Plastic Granules &amp; Raw Materials</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/plastic-granules-amp-raw-materials.jpg"
                                            alt="Plastic Granules & Raw Materials"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Recycled PET
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    LDPE Polymers
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    LDPE Granules
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    PP Granule
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Polymers</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/polymers.jpg"
                                            alt="Polymers"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Polyurethane
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Polycarbonate
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    PVC
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Polyethylene
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Agrochemicals</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <link
                                            rel="preload"
                                            as="image"
                                            href="/"
                                        />
                                        <figure
                                            className="cp"
                                            onclick="#"
                                        >
                                            <img
                                                src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/agrochemicals.jpg"
                                                alt="Agrochemicals"
                                                width={95}
                                                height={95}
                                                loading="lazy"
                                            />
                                        </figure>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Herbicides
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Insecticides
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Fungicides
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Growth Regulators
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Solvent</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/solvent.jpg"
                                            alt="Solvent"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Organic Solvents
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Methanol
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Isopropanol
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Butanol
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">

                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">
                                        Specialty Chemicals
                                    </a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <img
                                            src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/specialty-chemicals.jpg"
                                            alt="Specialty Chemicals"
                                            width={95}
                                            height={95}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Benzene
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Analytical Chemicals
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Specialty Fine Chemicals
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Polymer Chemicals
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4">
                            <div className="l1-box">
                                <h2 className="l1-heading">
                                    <a href="#">Acids</a>
                                </h2>
                                <div className="d-flex">
                                    <div>
                                        <link
                                            rel="preload"
                                            as="image"
                                            href="#"
                                        />
                                        <figure
                                            className="cp"
                                            onclick="#"
                                        >
                                            <img
                                                src="https://cdn.d4tcdn.com/staticdt/images/catg-imgs/chemical/acids.jpg"
                                                alt="Acids"
                                                width={95}
                                                height={95}
                                                loading="lazy"
                                            />
                                        </figure>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Hydrochloric Acid
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Sulfuric Acid
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Nitric Acid
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    Citric Acid
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    View More
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="content-page">
                        <p>
                            GlobalB2BMart is the leading Agriculture B2B Marketplace and Agriculture Business Directory, offering comprehensive business listings of Agriculture Suppliers, Manufacturers, Exporters, Wholesalers, and Traders worldwide. Our extensive and verified database includes detailed information about Agriculture Companies, a diverse catalog of Agriculture Products, and up-to-date Agriculture Price Lists and Quotations.
                        </p>
                    </div>

                </div>
            </div >

        </>
    )
}

export default Chemicals
import React from 'react';
import { Link } from 'react-router-dom';
import './common.css';

const classifiedData = [
    {
        _id: "5678ijkl1234mnop",
        name: "Sangram Nikam",
        email: "sangramnikam94@gmail.com",
        mobileNumber: "8208332266",
        companyName: "Home Made Products",
        productOrService: "Jaggery",
        imgSrc: "/assets/jaggery6.jpeg",  // Update with the actual image path
        mainProducts: "Organic Jaggery, Jaggery Powder, Solid Jaggery",
        altText: "Jaggery - Home Made Products",
        years: "1 YRS",
        location: "Pune, Maharashtra, India",
        tooltipText: "Pune, Maharashtra, India",
        rating: "4.6",
        ratingPercent: "92%",
        ratingsCount: "85",
        responseRate: "90%"
    },
    {
        "name": "Moorthy N",
        "email": "moorthynms.mba@gmail.com",
        "password": "Moorthy N",
        "mobileNumber": "8220958398",
        "companyName": "Hombale Export",
        "productOrService": "Jaggery",
        "imgSrc": "/assets/jaggery3.jpeg",
        "mainProducts": "Jaggery, Organic Sweeteners",
        "altText": "Hombale Export - Jaggery",
        "years": "1 YRS",
        "location": "Bangalore, Karnataka, India",
        "tooltipText": "Hombale Export, Bangalore",
        "rating": "4.7",
        "ratingPercent": "94%",
        "ratingsCount": "70",
        "responseRate": "89%",
        "whatsappConfirmed": true
    }

];

const JaggeryProviders = () => {
    return (
        <div className="main-box">
            <aside>
                <div className="flt-box-wrap">
                    <div className="flt-box mb-0 flt-head">Filters By</div>
                    <div className="flt-box bdrt-0">
                        <p className="flt-title">
                            Related Categories
                        </p>
                        <div className="flt-content">
                            <ul className="flt-list cust-scroll">
                                <li><Link to="#">Organic Jaggery</Link></li>
                                <li><Link to="#">Jaggery Powder</Link></li>
                                <li><Link to="#">Solid Jaggery</Link></li>
                                <li><Link to="#">Brown Sugar</Link></li>
                                <li><Link to="#">Sugarcane Jaggery</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="flt-box">
                        <p className="flt-title">
                            By State
                        </p>
                        <div className="flt-content">
                            <div className="flt-search">
                                <input type="text" name="state_id" placeholder="Search State" id="state-search-input" />
                            </div>
                            <ul className="flt-list cust-scroll" id="state-lists">
                                <li><Link to="#">All India</Link></li>
                                <li><Link to="#">Maharashtra</Link></li>
                                <li><Link to="#">Karnataka</Link></li>
                                <li><Link to="#">Gujarat</Link></li>
                                <li><Link to="#">Uttar Pradesh</Link></li>
                                <li><Link to="#">Tamil Nadu</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </aside>
            <main>
                <section className="section">
                    <p className="sect-title">Explore by Categories</p>
                    <div className="horprd expcatg" id="expcatg">
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/jaggery1.jpeg" width={55} height={55} alt="Organic Jaggery" />
                                    </figure>
                                    <p className="title">Organic Jaggery</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/jaggery2.jpeg" width={55} height={55} alt="Jaggery Powder" />
                                    </figure>
                                    <p className="title">Jaggery Powder</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/jaggery3.jpeg" width={55} height={55} alt="Solid Jaggery" />
                                    </figure>
                                    <p className="title">Solid Jaggery</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/jaggery4.jpeg" width={55} height={55} alt="Brown Sugar" />
                                    </figure>
                                    <p className="title">Brown Sugar</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/jaggery5.jpeg" width={55} height={55} alt="Sugarcane Jaggery" />
                                    </figure>
                                    <p className="title">Sugarcane Jaggery</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
                <ul className="classfied-wrap">
                    {classifiedData.map((item, index) => (
                        <li key={index}>
                            <div className="classified">
                                <div className="prd-info">
                                    <div className="prd-box">
                                        <img src={item.imgSrc} alt={item.altText} width={250} height={250} />
                                    </div>
                                </div>
                                <div className="cinfo">
                                    <div className="cbox">
                                        <figure>
                                            <span className="cmp-year">{item.years}</span>
                                        </figure>
                                        <div className="cboxr">
                                            <Link to="#" target="_blank"><h4 className="title">{item.companyName}</h4></Link>
                                            <p className="cloc tooltip ellipsis">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi-location" viewBox="0 0 16 16">
                                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                </svg>
                                                {item.location}
                                                <span className="tooltiptext">{item.tooltipText}</span>
                                            </p>
                                            <div className="rating-wrap">
                                                <span className="rtbox">{item.rating}</span>
                                                <span className="crate" style={{ "--_score": item.ratingPercent }} />
                                                <span className="rate-text">{item.ratingsCount} Ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="caddit">
                                        <div className="item">
                                            <div className="ca-box">
                                                <p><i className="l3icon resp-icon" /></p>
                                                <p><span>Response Rate</span> <b>{item.responseRate}</b></p>
                                            </div>
                                            <p><span>Main Products:</span> <b>{item.mainProducts}</b></p>
                                        </div>
                                    </div>
                                    <div className="caction">
                                        <Link to={'/register-buyer'} >
                                            <p>Contact Supplier</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </main>
        </div>
    );
}

export default JaggeryProviders;

// import React, { useState } from 'react';
// import axios from 'axios'; // Import Axios
// import './leads.css';

// const Leads = () => {
// const [formSubmitted, setFormSubmitted] = useState(false);

// const handleSubmit = async (event) => {
//     event.preventDefault();

//     const formData = {
//         name: event.target.name.value,
//         mobile: event.target.mobile.value,
//         email: event.target.email.value,
//         companyName: event.target.company.value,
//         userType: event.target.userType.value
//     };

//     try {
//         // Make a POST request to the API endpoint
//         await axios.post('http://localhost:3005/submit-form', formData);
//         setFormSubmitted(true);

//         event.target.reset();
//     } catch (error) {
//         console.error('Error submitting form:', error);

//     }
// };

//     return (
//         <div className="leads-container">
//             <div className="box-round buylead-wrap">
//                 <div className="sect-head">
//                     <h2 className="title">Fresh Leads</h2>
//                 </div>
//                 <div className="buylead-scroll">
//                     <div className="buylead-scroll">
//                         <div className="buylead">
//                             <div className="item">
//                                 <div className="buylead-box">
//                                     <div className="title">
//                                         <div>
//                                             <span onclick="/">
//                                                 Rice{" "}
//                                             </span>
//                                         </div>
//                                     </div>
//                                     <div className="location">
//                                         <div className="buylead-country">
//                                             <figure>
//                                                 <img
//                                                     src="./assets/flag.svg"
//                                                     alt="India"
//                                                     width={28}
//                                                     height={21}
//                                                     loading="lazy"
//                                                 />
//                                             </figure>
//                                             <div className="country">India</div>
//                                         </div>
//                                     </div>
//                                     <div className="action">
//                                         <span
//                                             className="btn"
//                                             onclick="/"
//                                         >
//                                             View Details
//                                         </span>
//                                         <div className="post-date">2024-05-13</div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="item">
//                                 <div className="buylead-box">
//                                     <div className="title">
//                                         <div>
//                                             <span onclick="/">
//                                                 Potatoes{" "}
//                                             </span>
//                                         </div>
//                                     </div>
//                                     <div className="location">
//                                         <div className="buylead-country">
//                                             <figure>
//                                                 <img
//                                                     src="./assets/flag.svg"
//                                                     alt="India"
//                                                     width={28}
//                                                     height={21}
//                                                     loading="lazy"
//                                                 />
//                                             </figure>
//                                             <div className="country">India</div>
//                                         </div>
//                                     </div>
//                                     <div className="action">
//                                         <span
//                                             className="btn"
//                                             onclick="/"
//                                         >
//                                             View Details
//                                         </span>
//                                         <div className="post-date">2024-05-13</div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="item">
//                                 <div className="buylead-box">
//                                     <div className="title">
//                                         <div>
//                                             <span onclick="/">
//                                                 Ghee{" "}
//                                             </span>
//                                         </div>
//                                     </div>
//                                     <div className="location">
//                                         <div className="buylead-country">
//                                             <figure>
//                                                 <img
//                                                     src="./assets/flag.svg"
//                                                     alt="India"
//                                                     width={28}
//                                                     height={21}
//                                                     loading="lazy"
//                                                 />
//                                             </figure>
//                                             <div className="country">India</div>
//                                         </div>
//                                     </div>
//                                     <div className="action">
//                                         <span
//                                             className="btn"
//                                             onclick="/"
//                                         >
//                                             View Details
//                                         </span>
//                                         <div className="post-date">2024-05-13</div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="item">
//                                 <div className="buylead-box">
//                                     <div className="title">
//                                         <div>
//                                             <span onclick="/">
//                                                 Cigarettes{" "}
//                                             </span>
//                                         </div>
//                                     </div>
//                                     <div className="location">
//                                         <div className="buylead-country">
//                                             <figure>
//                                                 <img
//                                                     src="./assets/flag.svg"
//                                                     alt="India"
//                                                     width={28}
//                                                     height={21}
//                                                     loading="lazy"
//                                                 />
//                                             </figure>
//                                             <div className="country">India</div>
//                                         </div>
//                                     </div>
//                                     <div className="action">
//                                         <span
//                                             className="btn"
//                                             onclick="/"
//                                         >
//                                             View Details
//                                         </span>
//                                         <div className="post-date">2024-05-13</div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="item">
//                                 <div className="buylead-box">
//                                     <div className="title">
//                                         <div>
//                                             <span onclick="/">
//                                                 Chemicals{" "}
//                                             </span>
//                                         </div>
//                                     </div>
//                                     <div className="location">
//                                         <div className="buylead-country">
//                                             <figure>
//                                                 <img
//                                                     src="./assets/flag.svg"
//                                                     alt="India"
//                                                     width={28}
//                                                     height={21}
//                                                     loading="lazy"
//                                                 />
//                                             </figure>
//                                             <div className="country">India</div>
//                                         </div>
//                                     </div>
//                                     <div className="action">
//                                         <span
//                                             className="btn"
//                                             onclick="/"
//                                         >
//                                             View Details
//                                         </span>
//                                         <div className="post-date">2024-05-13</div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="item">
//                                 <div className="buylead-box">
//                                     <div className="title">
//                                         <div>
//                                             <span onclick="/">
//                                                 Air Handling Units{" "}
//                                             </span>
//                                         </div>
//                                     </div>
//                                     <div className="location">
//                                         <div className="buylead-country">
//                                             <figure>
//                                                 <img
//                                                     src="./assets/flag.svg"
//                                                     alt="India"
//                                                     width={28}
//                                                     height={21}
//                                                     loading="lazy"
//                                                 />
//                                             </figure>
//                                             <div className="country">India</div>
//                                         </div>
//                                     </div>
//                                     <div className="action">
//                                         <span
//                                             className="btn"
//                                             onclick="/"
//                                         >
//                                             View Details
//                                         </span>
//                                         <div className="post-date">2024-05-13</div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="item">
//                                 <div className="buylead-box">
//                                     <div className="title">
//                                         <div>
//                                             <span onclick="/">
//                                                 Thin Cutting Grinding Whe{" "}
//                                             </span>
//                                         </div>
//                                     </div>
//                                     <div className="location">
//                                         <div className="buylead-country">
//                                             <figure>
//                                                 <img
//                                                     src="./assets/flag.svg"
//                                                     alt="India"
//                                                     width={28}
//                                                     height={21}
//                                                     loading="lazy"
//                                                 />
//                                             </figure>
//                                             <div className="country">India</div>
//                                         </div>
//                                     </div>
//                                     <div className="action">
//                                         <span
//                                             className="btn"
//                                             onclick="/"
//                                         >
//                                             View Details
//                                         </span>
//                                         <div className="post-date">2024-05-13</div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="item">
//                                 <div className="buylead-box">
//                                     <div className="title">
//                                         <div>
//                                             <span onclick="/">
//                                                 Ems 907 Generator Control{" "}
//                                             </span>
//                                         </div>
//                                     </div>
//                                     <div className="location">
//                                         <div className="buylead-country">
//                                             <figure>
//                                                 <img
//                                                     src="./assets/flag.svg"
//                                                     alt="India"
//                                                     width={28}
//                                                     height={21}
//                                                     loading="lazy"
//                                                 />
//                                             </figure>
//                                             <div className="country">India</div>
//                                         </div>
//                                     </div>
//                                     <div className="action">
//                                         <span
//                                             className="btn"
//                                             onclick="/"
//                                         >
//                                             View Details
//                                         </span>
//                                         <div className="post-date">2024-05-13</div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="form-wrap">

//                 <h2 style={{ margin: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Post Your Requirement</h2>
//                 {formSubmitted && (
//                     <p style={{ textAlign: 'center', color: 'green' }}>Form submitted successfully!</p>
//                 )}
//                 <form onSubmit={handleSubmit}>
//                     <div className="form-group">
//                         <label htmlFor="name">Name:</label>
//                         <input className='form-input' type="text" required id="name" name="name" />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="mobile">Mobile Number:</label>
//                         <input className='form-input' type="tel" required id="mobile" name="mobile" />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="email">Email:</label>
//                         <input className='form-input' type="email" required id="email" name="email" />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="company">Company Name:</label>
//                         <input className='form-input' type="text" required id="company" name="company" />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="userType">Select User Type:</label>
//                         <select id="userType" name="userType" className="form-control" required>
//                             <option value="">Select User Type</option>
//                             <option value="seller">Seller</option>
//                             <option value="buyer">Buyer</option>
//                         </select>
//                     </div>
//                     <button type="submit">Submit</button>
//                 </form>
//             </div>
//         </div>
//     );
// }

// export default Leads;
// import React, { useState } from 'react';
// import axios from 'axios'; // Import Axios
// import './leads.css';

// const Leads = () => {
//     const [formSubmitted, setFormSubmitted] = useState(false);

//     const handleSubmit = async (event) => {
//         event.preventDefault();

//         const formData = {
//             name: event.target.name.value,
//             mobile: event.target.mobile.value,
//             email: event.target.email.value,
//             companyName: event.target.company.value,
//             userType: event.target.userType.value
//         };

//         try {
//             // Make a POST request to the API endpoint
//             await axios.post('http://localhost:3005/submit-form', formData);
//             setFormSubmitted(true);

//             event.target.reset();
//         } catch (error) {
//             console.error('Error submitting form:', error);

//         }
//     };

//     const leadsData = [
//         {
//             title: "Rice",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Potatoes",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Ghee",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Cigarettes",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Chemicals",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         // {
//         //     title: "Air Handling Units",
//         //     country: "India",
//         //     postDate: "2024-05-13"
//         // },
//         // {
//         //     title: "Thin Cutting Grinding Whe",
//         //     country: "India",
//         //     postDate: "2024-05-13"
//         // },
//         // {
//         //     title: "Ems 907 Generator Control",
//         //     country: "India",
//         //     postDate: "2024-05-13"
//         // }
//     ];

//     return (
//         <div className="leads-container">
//             <div className="box-round buylead-wrap">
//                 <div className="sect-head">
//                     <h2 className="title">Fresh Leads</h2>
//                 </div>
//                 <div className="buylead-scroll">
//                     <div className="buylead">
//                         {leadsData.map((lead, index) => (
//                             <div className="item" key={index}>
//                                 <div className="buylead-box">
//                                     <div className="title">
//                                         <div>
//                                             <span onClick={() => { window.location.href = '/' }}>
//                                                 {lead.title}
//                                             </span>
//                                         </div>
//                                     </div>
//                                     <div className="location">
//                                         <div className="buylead-country">
//                                             <figure>
//                                                 <img
//                                                     src="./assets/flag.svg"
//                                                     alt={lead.country}
//                                                     width={28}
//                                                     height={21}
//                                                     loading="lazy"
//                                                 />
//                                             </figure>
//                                             <div className="country">{lead.country}</div>
//                                         </div>
//                                     </div>
//                                     <div className="action">
//                                         <span
//                                             className="btn"
//                                             onClick={() => { window.location.href = '/' }}
//                                         >
//                                             View Details
//                                         </span>
//                                         <div className="post-date">{lead.postDate}</div>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>

//                 </div>
//             </div>
//             <div className="form-wrap">

//                 <h2 style={{ margin: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Post Your Requirement</h2>
//                 {formSubmitted && (
//                     <p style={{ textAlign: 'center', color: 'green' }}>Form submitted successfully!</p>
//                 )}
//                 <form onSubmit={handleSubmit}>
//                     <div className="form-group">
//                         <label htmlFor="name">Name:</label>
//                         <input className='form-input' type="text" required id="name" name="name" />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="mobile">Mobile Number:</label>
//                         <input className='form-input' type="tel" required id="mobile" name="mobile" />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="email">Email:</label>
//                         <input className='form-input' type="email" required id="email" name="email" />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="company">Company Name:</label>
//                         <input className='form-input' type="text" required id="company" name="company" />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="userType">Select User Type:</label>
//                         <select id="userType" name="userType" className="form-control" required>
//                             <option value="">Select User Type</option>
//                             <option value="seller">Seller</option>
//                             <option value="buyer">Buyer</option>
//                         </select>
//                     </div>
//                     <button type="submit">Submit</button>
//                 </form>
//             </div>

//         </div>
//     );
// };

// export default Leads;
// import React, { useState } from 'react';
// import axios from 'axios'; // Import Axios
// import './leads.css';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import { Autoplay, Pagination, Navigation } from 'swiper/modules';


// const Leads = () => {
//     const [formSubmitted, setFormSubmitted] = useState(false);

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         const formData = {
//             name: event.target.name.value,
//             mobile: event.target.mobile.value,
//             email: event.target.email.value,
//             companyName: event.target.company.value,
//             userType: event.target.userType.value
//         };

//         try {
//             // Make a POST request to the API endpoint
//             // await axios.post('http://localhost:3005/submit-form', formData);
//             await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/submit-form`, formData);
//             setFormSubmitted(true);
//             setFormSubmitted(true);
//             event.target.reset();
//         } catch (error) {
//             console.error('Error submitting form:', error);
//         }
//     };

//     const leadsData = [
//         {
//             title: "Rice",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Potatoes",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Ghee",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Cigarettes",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Chemicals",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Air Handling Units",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Thin Cutting ",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Ems 907 Generator ",
//             country: "India",
//             postDate: "2024-05-13"
//         }, {
//             title: "Cigarettes",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Chemicals",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Chemicals",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Air Handling Units",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Thin Cutting ",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Chemicals",
//             country: "India",
//             postDate: "2024-05-13"
//         },
//         {
//             title: "Air Handling Units",
//             country: "India",
//             postDate: "2024-05-13"
//         },


//     ];

//     // Chunk the leads data into groups of 5
//     const chunkSize = 5;
//     const chunkedLeadsData = [];
//     for (let i = 0; i < leadsData.length; i += chunkSize) {
//         chunkedLeadsData.push(leadsData.slice(i, i + chunkSize));
//     }

//     return (
//         <div className="leads-container">
//             <div className="box-round buylead-wrap">
//                 <div className="sect-head">
//                     <h2 className="title">Fresh Leads</h2>
//                 </div>
//                 <Swiper
//                     spaceBetween={30}
//                     centeredSlides={true}
//                     autoplay={{
//                         delay: 2500,
//                         disableOnInteraction: false,
//                     }}
//                     pagination={{
//                         clickable: true,
//                     }}
//                     navigation={true}
//                     modules={[Autoplay, Pagination, Navigation]}
//                     className="mySwiper"
//                 >
//                     {chunkedLeadsData.map((chunk, index) => (
//                         <SwiperSlide key={index}>

//                             <div className="buylead-scroll" style={{ width: '100%' }}>
//                                 <div className="buylead">
//                                     {chunk.map((lead, leadIndex) => (
//                                         <div className="item" key={leadIndex}>
//                                             <div className="buylead-box">
//                                                 <div className="title">
//                                                     <div>
//                                                         <span onClick={() => { window.location.href = '/' }}>
//                                                             {lead.title}
//                                                         </span>
//                                                     </div>
//                                                 </div>
//                                                 <div className="location">
//                                                     <div className="buylead-country">
//                                                         <figure>
//                                                             <img
//                                                                 src="./assets/flag.svg"
//                                                                 alt={lead.country}
//                                                                 width={28}
//                                                                 height={21}
//                                                                 loading="lazy"
//                                                             />
//                                                         </figure>
//                                                         <div className="country">{lead.country}</div>
//                                                     </div>
//                                                 </div>
//                                                 <div className="action">
//                                                     <span
//                                                         className="btn"
//                                                         onClick={() => { window.location.href = '/' }}
//                                                     >
//                                                         View Details
//                                                     </span>
//                                                     <div className="post-date">{lead.postDate}</div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     ))}
//                                 </div>
//                             </div>
//                         </SwiperSlide>
//                     ))}
//                 </Swiper>
//             </div>
//             <div className="form-wrap">
//                 <h2 style={{ margin: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Post Your Requirement</h2>
//                 {formSubmitted && (
//                     <p style={{ textAlign: 'center', color: 'green' }}>Form submitted successfully!</p>
//                 )}
//                 <form onSubmit={handleSubmit} style={{ marginTop: '30px' }}>
//                     <div className="form-group">
//                         <label htmlFor="name">Name:</label>
//                         <input className='form-input' type="text" required id="name" name="name" />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="mobile">Mobile Number:</label>
//                         <input className='form-input' type="tel" required id="mobile" name="mobile" />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="email">Email:</label>
//                         <input className='form-input' type="email" required id="email" name="email" />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="company">Company Name:</label>
//                         <input className='form-input' type="text" required id="company" name="company" />
//                     </div>
//                     <div className="form-group">
//                         <label htmlFor="userType">Select User Type:</label>
//                         <select id="userType" name="userType" className="form-control" required>
//                             <option value="">Select User Type</option>
//                             <option value="seller">Seller</option>
//                             <option value="buyer">Buyer</option>
//                         </select>
//                     </div>
//                     <button type="submit">Submit</button>
//                 </form>
//             </div>
//         </div>
//     );
// };

// export default Leads;


import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import './leads.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

const Leads = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            name: event.target.name.value,
            mobile: event.target.mobile.value,
            email: event.target.email.value,
            companyName: event.target.company.value,
            userType: event.target.userType.value
        };

        try {


            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/submit-form`, formData);
            console.log('API Endpoint:', process.env.REACT_APP_API_ENDPOINT);

            setFormSubmitted(true);
            event.target.reset();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    

    const leadsData = [
        {
            "title": "Red Chilli",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },
        {
            "title": "Mustard Seeds",
            "country": "Romania",
            "postDate": "18-09-2024",
            "imagePath": '/assets/romania.jpeg'
        },
        {
            "title": "Apple",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },
        {
            "title": "Wheat",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },

        {
            "title": "Ghee",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },

        {
            "title": "Cow Ghee",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },
        {
            "title": "Noodle Making Machine",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },
        {
            "title": "Coffee Beans",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },
        {
            "title": "Pulses",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },
        {
            "title": "Banana",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },

        {
            "title": "Rice",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },
        {
            "title": "Pulses",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },

        {
            "title": "Apples",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },
        {
            "title": "Cow Ghee",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },
        {
            "title": "Turmeric Finger",
            "country": "India",
            "postDate": "18-09-2024",
            "imagePath": '/assets/flag.svg'
        },









    ];

    // Chunk the leads data into groups of 5
    const chunkSize = 5;
    const chunkedLeadsData = [];
    for (let i = 0; i < leadsData.length; i += chunkSize) {
        chunkedLeadsData.push(leadsData.slice(i, i + chunkSize));
    }

    return (
        <div className="leads-container">
            <div className="box-round buylead-wrap">
                <div className="sect-head">
                    <h2 className="title">Fresh Buyers</h2>
                </div>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {chunkedLeadsData.map((chunk, index) => (
                        <SwiperSlide key={index}>

                            <div className="buylead-scroll" style={{ width: '100%' }}>
                                <div className="buylead">
                                    {chunk.map((lead, leadIndex) => (
                                        <div className="item" key={leadIndex}>
                                            <div className="buylead-box">
                                                <div className="title">
                                                    <div>
                                                        <span>
                                                            {lead.title}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="location">
                                                    <div className="buylead-country">
                                                        {/* <figure>
                                                            <img
                                                                src="./assets/flag.svg"
                                                                alt={lead.country}
                                                                width={28}
                                                                height={21}
                                                                loading="lazy"
                                                            />
                                                            
                                                        </figure> */}
                                                        <figure>
                                                            <img
                                                                src={lead.imagePath}
                                                                alt={lead.country}
                                                                width={28}
                                                                height={21}
                                                                loading="lazy"
                                                            />
                                                        </figure>
                                                        <div className="country">{lead.country}</div>
                                                    </div>
                                                </div>
                                                <div className="action">
                                                    <Link to={'/login'}>
                                                        <span
                                                            className="btn"
                                                        // onClick={() => { window.location.href = '/login' }}

                                                        >
                                                            View Details
                                                        </span>
                                                    </Link>
                                                    <div className="post-date">{lead.postDate}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="form-wrap">
                <h2 style={{ margin: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Post Your Requirement</h2>
                {formSubmitted && (
                    <p style={{ textAlign: 'center', color: 'green' }}>Form submitted successfully!</p>
                )}
                <form onSubmit={handleSubmit} style={{ marginTop: '30px' }}>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input className='form-input' type="text" required id="name" name="name" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mobile">Mobile Number:</label>
                        <input className='form-input' type="tel" required id="mobile" name="mobile" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input className='form-input' type="email" required id="email" name="email" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="company">Company Name:</label>
                        <input className='form-input' type="text" required id="company" name="company" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="userType">Select User Type:</label>
                        <select id="userType" name="userType" className="form-control" required>
                            <option value="">Select User Type</option>
                            <option value="seller">Seller</option>
                            <option value="buyer">Buyer</option>
                        </select>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default Leads;


import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './tesi.css';

// import required modules
import { Pagination } from 'swiper/modules';

const testimonials = [
    { id: 1, name: 'Sanjeev', position: 'Owner', review: 'I have been using globalb2bmart for a few months now and I must say, it has been a game changer for my business.' },
    { id: 2, name: 'Naveen', position: 'Owner', review: 'I appreciate most about globalb2bmart is the wide range of suppliers available on the platform. ' },
    { id: 3, name: 'Raghav', position: 'Owner', review: 'The user-friendly interface of globalb2bmart makes it easy for me to navigate and search for specific products or suppliers. I can filter my search based on location, product category.' },


    { id: 4, name: 'Himesh', position: 'Owner', review: 'I love about globalb2bmart is the ease of use. The website is user-friendly and it was very easy for me to set up my profile and start connecting with potential customers.' },

    // { id: 5, name: 'Neeraj', position: 'Owner', review: 'Great feature of globalb2bmart is the ability to showcase my products and services. The platform allows me to create a detailed profile with images, videos, and descriptions of my offerings. This has not only helped me to attract potential customers, but also to stand out from my competitors.' },
    { id: 1, name: 'Sanjeev', position: 'Owner', review: 'I have been using globalb2bmart for a few months now and I must say, it has been a game changer for my business.' },
    { id: 2, name: 'Naveen', position: 'Owner', review: 'I appreciate most about globalb2bmart is the wide range of suppliers available on the platform. ' },
];

export default function Testimonial() {
    return (
        <div className="testimonial-container">
            <h1 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px 10px ' }}> Testimonial</h1>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                }}
                modules={[Pagination]}
                className="mySwiper"

            >

                {testimonials.map(testimonial =>

                (

                    <SwiperSlide key={testimonial.id}>

                        <div className="testimonial-box">
                            <p className="testimonial-review">{testimonial.review}</p>
                            <p className="testimonial-name">{testimonial.name}</p>
                            <p className="testimonial-position">{testimonial.position}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

import React from "react";
import { Link } from "react-router-dom";
import CompanyForm from "./Companyform"; // Ensure this import path is correct
import './onioncomp.css'; // Adjust the CSS file path as necessary

const AgmecoFaucetsComp = () => {
    // Company data for Agmeco Faucets Pvt. Ltd.
    const companyData = {
        name: "Agmeco Faucets Pvt. Ltd.",
        email: "info@agmeco.com",
        mobileNumber: "8470012000",
        companyName: "Agmeco Faucets Pvt. Ltd.",
        productOrService: "Bathroom Accessories Manufacturer",
        productDescribe: "Agmeco Faucets Pvt. Ltd. is a leading manufacturer of high-quality bathroom accessories. Our extensive range includes faucets, showerheads, and other essential fittings designed to enhance the functionality and aesthetics of any bathroom.",
        businessType: "Manufacturer",
        mainProducts: "Bathroom Accessories",
        logoUrl: "/assets/tap11.jpeg", // Example path to company logo image
    };

    return (
        <>
            <div className="container">
                <ol className="breadcrumb">
                    <li style={{ display: 'flex', justifyContent: 'center', margin: '5px 30px' }} className="breadcrumb-item">
                        <Link to={'/'}>Home</Link>
                    </li>
                    <li style={{ display: 'flex', justifyContent: 'center', margin: '5px -25px' }} >/</li>
                    <li style={{ display: 'flex', justifyContent: 'center', margin: '5px 30px' }} className="breadcrumb-item active">Search</li>
                </ol>
            </div>
            <div className="b2b-page">
                <div className="content-left">
                    {/* Display Agmeco Faucets Pvt. Ltd. information */}
                    <div className="company-card">
                        <div className="company-header">
                            <div className="company-logo">
                                <img src={companyData.logoUrl} alt="Company Logo" />
                            </div>
                            <div className="company-details">
                                <h3>{companyData.companyName}</h3>
                                <p><strong>Name:</strong> {companyData.name}</p>
                                <p><strong>Description:</strong> {companyData.productDescribe}</p>
                                <div className="imp-btns">
                                    <button style={{ backgroundColor: '#4ee54e' }} className="main-btns">
                                        <Link to={'/register-buyer'}>Connect Now</Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="company-description">
                            <p><strong>Business Type:</strong> {companyData.businessType}</p>
                            <p><strong>Main Products:</strong> {companyData.mainProducts}</p>
                        </div>
                    </div>
                </div>
                <CompanyForm />
            </div>
        </>
    );
}

export default AgmecoFaucetsComp;

import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/navigation';


// import required modules
import './allcat.css';

const countrylist = [
    { id: 1, name: 'United Kingdom', image: '/assets/ukss.jpeg', link: '/uk' },

    { id: 3, name: 'USA', image: '/assets/usasr.jpeg', link: '/usa' },

    { id: 5, name: 'Australia', image: './assets/ausp1.jpeg', link: '/australia' },
    { id: 6, name: 'Canada', image: '/assets/candass1.avif', link: '/canada' },
    { id: 4, name: 'Germany', image: '/assets/grr.jpeg', link: '/germany' },

    { id: 8, name: 'Oman', image: '/assets/omans.jpeg', link: '/oman' },
    { id: 2, name: 'China', image: '/assets/chians.jpeg', link: '/china' },
    { id: 9, name: 'Netherland', image: '/assets/neithers.jpeg', link: '/netherland' },
    // Add more countrylist as needed
];

export default function StateCon() {
    const [swiperInstance, setSwiperInstance] = useState(null);

    const handleReachEnd = () => {
        if (swiperInstance) {
            const isEnd = swiperInstance.isEnd;
            const navigationButton = document.querySelector('.swiper-button-next');
            if (navigationButton) {
                navigationButton.style.display = isEnd ? 'none' : 'block';
            }
        }
    };

    const handleReachStart = () => {
        if (swiperInstance) {
            const isBeginning = swiperInstance.isBeginning;
            const navigationButton = document.querySelector('.swiper-button-prev');
            if (navigationButton) {
                navigationButton.style.display = isBeginning ? 'none' : 'block';
            }
        }
    };

    return (
        <>
            <Link to={'/all/country'}>
                <button id='view-all' style={{ float: 'right', backgroundColor: 'transparent', color: 'black', border: 'none', marginRight: '29px', marginTop: '30px', cursor: 'pointer' }}>View All <FontAwesomeIcon icon={faArrowRight} /></button>
            </Link>

            <div style={{ padding: '5px 10px' }}>
                <h2 style={{ padding: '10px 20px' }}>Find suppliers by <span style={{ color: 'orange' }} > Country</span> </h2>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{ clickable: true }}
                    onSwiper={setSwiperInstance}
                    onReachEnd={handleReachEnd}
                    onReachBeginning={handleReachStart}
                    breakpoints={{
                        640: { slidesPerView: 2, spaceBetween: 20 },
                        768: { slidesPerView: 4, spaceBetween: 40 },
                        1024: { slidesPerView: 7, spaceBetween: 30 },
                    }}
                    className="mySwiper"
                >
                    {countrylist.map(countries => (
                        <SwiperSlide key={`countries-${countries.id}`}>
                            <Link to={countries.link}>
                                <div className="card">
                                    <img src={countries.image} alt={countries.name} />
                                    <p>{countries.name}</p>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="market">
                    <h1>Best B2B Portal In India | Globalb2bmart</h1>
                    <p>
                        GlobalB2Bmart is the best B2B portal in India, connecting business enterprises across more than 300 categories: agriculture, clothing and Fashion, Products, Beverages, Home Supplies, etc. We are proud to be the platform where suppliers and buyers come together to build concrete business relationships.
                        <h2 style={{ textAlign: 'left', color: '#2a3646', padding: '10px 0px' }}>Best B2B Website in India</h2>
                        <p>Being the best B2B website in India, GlobalB2Bmart brings suppliers the best opportunities. Our verified customers will surely fulfill all of your business needs. Through our user-friendly platform, suppliers can showcase their products before a wide audience to grow their business with ease.</p>
                        <h2 style={{ textAlign: 'left', color: '#2a3646', padding: '10px 0px' }}>Best B2B Company in India</h2>
                        <p>GlobalB2Bmart is the Best B2B Company in India. We do not compromise on quality since it makes your business a success story in a competitive marketplace.Our first priority is customer satisfaction, and we provide a transparent medium between buyers and suppliers.</p>
                        <h2 style={{ textAlign: 'left', color: '#2a3646', padding: '10px 0px' }}>Best business to business portal in India.</h2>
                        <p>GlobalB2Bmart, being the best  business to business portal in India, assures trust and transparency. We are a platform of verified and trusted suppliers who also offer a variety of value-added services, starting from website creation to SEO optimization and GMB listing. Obviously, our platform is meant to take your business to greater heights.</p>

                    </p>
                    <h2 style={{ textAlign: 'left', color: '#2a3646', padding: '10px 0px' }}>Best B2B Platform in India</h2>
                    <p>Join the best B2B platform in India and avail our business-oriented plans—styled to suit different business needs with services like domains, hosting, maintenance, and unlimited access for buyer inquiries. With our platform, it will give the necessary attention to your business.</p>
                    <h2 style={{ textAlign: 'left', color: '#2a3646', padding: '10px 0px' }}>Best B2B Services in India</h2>
                    <p>GlobalB2Bmart provides the best B2B services in India. From verified and contactable buyers to website security certificates. we offer a variety of services, including brand promotion and many more That is why we stand out as the best Best B2B Service provider in india</p>
                </div>

            </div>
        </>
    );
}

import React from 'react';
import { Link } from 'react-router-dom';
import './common.css';

const classifiedData = [

    {
        "name": "Mr. Sahid",
        "email": "nizamtrip@gmail.com",
        "password": "sahid@123",
        "mobileNumber": "9624018168",
        "companyName": "Mizaan The Trip House",
        "productOrService": "Tours & Travel",
        "imgSrc": "/assets/tr1.jpg",
        "altText": "Tours & Travel - Mizaan The Trip House",
        "mainProducts": "Customized Tours, Adventure Trips, Family Vacations",
        "years": "1 YRS",
        "location": "delhi india",
        "tooltipText": "789 Trip Lane, Travel Park, Random Place",
        "rating": "4.7",
        "ratingPercent": "94%",
        "ratingsCount": "95",
        "responseRate": "98%"
    },
    {
        "_id": "a1234567890wxyz5686",
        "name": "ASHOK",
        "email": "UMBOODEEPDVENTURES14325@gmail.com",
        "password": "ASHOK 123",
        "mobileNumber": "9811945211",
        "companyName": "UMBOODEEP ADVENTURES & TOURS PVT LTD",
        "productOrService": "UMBOODEEP ADVENTURES & TOURS PVT LTD",
        "imgSrc": "/assets/tour1.jpeg",  // Placeholder for image path
        "mainProducts": "Tour Packages, Adventure Tours",
        "altText": "Tour and Adventure Services - UMBOODEEP ADVENTURES & TOURS PVT LTD",
        "years": "1 YRS",  // Placeholder for years of experience
        "location": "India",  // Placeholder for location
        "tooltipText": "UMBOODEEP ADVENTURES & TOURS PVT LTD, India",
        "rating": "4.7",  // Placeholder for rating
        "ratingPercent": "94%",  // Placeholder for rating percentage
        "ratingsCount": "80",  // Placeholder for ratings count
        "responseRate": "88%",  // Placeholder for response rate
        "whatsappConfirmed": false
    }

];

const ToursAndTravel = () => {
    return (
        <div className="main-box">
            <aside>
                <div className="flt-box-wrap">
                    <div className="flt-box mb-0 flt-head">Filters By</div>
                    <div className="flt-box bdrt-0">
                        <p className="flt-title">
                            Related Categories
                        </p>
                        <div className="flt-content">
                            <ul className="flt-list cust-scroll">
                                <li><Link to="#">Shimla Tour</Link></li>
                                <li><Link to="#">Manali Tour</Link></li>
                                <li><Link to="#">Goa Tour</Link></li>
                                <li><Link to="#">Adventure Trips</Link></li>
                                <li><Link to="#">Family Vacations</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="flt-box">
                        <p className="flt-title">
                            By State
                        </p>
                        <div className="flt-content">
                            <div className="flt-search">
                                <input type="text" name="state_id" placeholder="Search State" id="state-search-input" />
                            </div>
                            <ul className="flt-list cust-scroll" id="state-lists">
                                <li><Link to="#">All India</Link></li>
                                <li><Link to="#">Maharashtra</Link></li>
                                <li><Link to="#">Gujarat</Link></li>
                                <li><Link to="#">Tamil Nadu</Link></li>
                                <li><Link to="#">Karnataka</Link></li>
                                <li><Link to="#">Rajasthan</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </aside>
            <main>
                <section className="section">
                    <p className="sect-title">Explore by Categories</p>
                    <div className="horprd expcatg" id="expcatg">
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/tr1.jpg" width={55} height={55} alt="Shimla Tour" />
                                    </figure>
                                    <p className="title">Shimla Tour</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/tr2.jpg" width={55} height={55} alt="Manali Tour" />
                                    </figure>
                                    <p className="title">Manali Tour</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/tr3.jpg" width={55} height={55} alt="Goa Tour" />
                                    </figure>
                                    <p className="title">Goa Tour</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/tr4.jpg" width={55} height={55} alt="Adventure Trips" />
                                    </figure>
                                    <p className="title">Adventure Trips</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/tr5.jpg" width={55} height={55} alt="Family Vacations" />
                                    </figure>
                                    <p className="title">Family Vacations</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
                <ul className="classfied-wrap">
                    {classifiedData.map((item, index) => (
                        <li key={index}>
                            <div className="classified">
                                <div className="prd-info">
                                    <div className="prd-box">
                                        <img src={item.imgSrc} alt={item.altText} width={250} height={250} />
                                    </div>
                                </div>
                                <div className="cinfo">
                                    <div className="cbox">
                                        <figure>
                                            <span className="cmp-year">{item.years}</span>
                                        </figure>
                                        <div className="cboxr">
                                            <Link to="#" target="_blank"><h4 className="title">{item.companyName}</h4></Link>
                                            <p className="cloc tooltip ellipsis">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi-location" viewBox="0 0 16 16">
                                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                </svg>
                                                {item.location}
                                                <span className="tooltiptext">{item.tooltipText}</span>
                                            </p>
                                            <div className="rating-wrap">
                                                <span className="rtbox">{item.rating}</span>
                                                <span className="crate" style={{ "--_score": item.ratingPercent }} />
                                                <span className="rate-text">{item.ratingsCount} Ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="caddit">
                                        <div className="item">
                                            <div className="ca-box">
                                                <p><i className="l3icon resp-icon" /></p>
                                                <p><span>Response Rate</span> <b>{item.responseRate}</b></p>
                                            </div>
                                            <p><span>Main Products:</span> <b>{item.mainProducts}</b></p>
                                        </div>
                                    </div>
                                    <div className="caction">
                                        <Link to={'/register-buyer'} >
                                            <p>Contact Supplier</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </main>
        </div>
    );
}

export default ToursAndTravel;

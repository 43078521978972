import React from 'react';
import { Link } from 'react-router-dom';
import './common.css';

const riceDealersData = [
    {
        name: "Geetanjali",
        email: "Geetanjali@gmail.com",
        mobileNumber: "7032855846",
        companyName: "Modi Care",
        productOrService: "Rice Dealer",
        imgSrc: "/assets/rice1.jpeg",  // Provide the correct image path
        altText: "Rice Dealer - Modi Care",
        mainProducts: "Basmati Rice, Sona Masoori Rice, Brown Rice, etc.",
        years: "1 YRS",
        location: "Delhi, India",
        tooltipText: "789, Chandni Chowk, Delhi - 110006, India",
        rating: "4.7",
        ratingPercent: "94%",
        ratingsCount: "180",
        responseRate: "96%"
    },
    {
        "_id": "j1234567890k012347",
        "name": "Ashok Gangadhar Shinde",
        "email": "ashok765@gmail.com",
        "password": "ashok@123",
        "mobileNumber": "9049860144",
        "companyName": "Kaveri Gul V Sheti Utpadane",
        "productOrService": "Rice & Agro Product Exporter",
        "imgSrc": "/assets/rice3.jpeg",
        "mainProducts": "Basmati Rice, Organic Rice, Agro Products",
        "altText": "Rice & Agro Product Exporter - Kaveri Gul V Sheti Utpadane",
        "years": "1 YRS",
        "location": "Nashik, Maharashtra, India",
        "tooltipText": "Sector 5, Agricultural Zone, Nashik, Maharashtra, India",
        "rating": "4.6",
        "ratingPercent": "92%",
        "ratingsCount": "60",
        "responseRate": "90%",
        "whatsappConfirmed": true
    },
    // Additional rice dealers can be added here.
];

const RiceDealers = () => {
    return (
        <div className="main-box">
            <aside>
                <div className="flt-box-wrap">
                    <div className="flt-box mb-0 flt-head">Filters By</div>
                    <div className="flt-box bdrt-0">
                        <p className="flt-title">Rice Varieties</p>
                        <div className="flt-content">
                            <ul className="flt-list cust-scroll">
                                <li><Link to="#">Basmati Rice</Link></li>
                                <li><Link to="#">Sona Masoori Rice</Link></li>
                                <li><Link to="#">Jasmine Rice</Link></li>
                                <li><Link to="#">Brown Rice</Link></li>
                                <li><Link to="#">Parboiled Rice</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="flt-box">
                        <p className="flt-title">By Location</p>
                        <div className="flt-content">
                            <div className="flt-search">
                                <input type="text" name="location_id" placeholder="Search Location" id="location-search-input" />
                            </div>
                            <ul className="flt-list cust-scroll" id="location-lists">
                                <li><Link to="#">All India</Link></li>
                                <li><Link to="#">Delhi</Link></li>
                                <li><Link to="#">Mumbai</Link></li>
                                <li><Link to="#">Chennai</Link></li>
                                <li><Link to="#">Bengaluru</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </aside>
            <main>
                <section className="section">
                    <p className="sect-title">Explore Rice Varieties</p>
                    <div className="horprd expcatg" id="expcatg">
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/rice2.jpeg" width={55} height={55} alt="Basmati Rice" />
                                    </figure>
                                    <p className="title">Basmati Rice</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/rice3.jpeg" width={55} height={55} alt="Sona Masoori Rice" />
                                    </figure>
                                    <p className="title">Sona Masoori Rice</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/rice4.jpeg" width={55} height={55} alt="Jasmine Rice" />
                                    </figure>
                                    <p className="title">Jasmine Rice</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/rice5.jpeg" width={55} height={55} alt="Brown Rice" />
                                    </figure>
                                    <p className="title">Brown Rice</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <div className="horprd-box">
                                    <figure>
                                        <img src="/assets/rice1.jpeg" width={55} height={55} alt="Parboiled Rice" />
                                    </figure>
                                    <p className="title">Parboiled Rice</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
                <ul className="classfied-wrap">
                    {riceDealersData.map((item, index) => (
                        <li key={index}>
                            <div className="classified">
                                <div className="prd-info">
                                    <div className="prd-box">
                                        <img src={item.imgSrc} alt={item.altText} width={250} height={250} />
                                    </div>
                                </div>
                                <div className="cinfo">
                                    <div className="cbox">
                                        <figure>
                                            <span className="cmp-year">{item.years}</span>
                                        </figure>
                                        <div className="cboxr">
                                            <Link to="#" target="_blank"><h4 className="title">{item.companyName}</h4></Link>
                                            <p className="cloc tooltip ellipsis">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi-location" viewBox="0 0 16 16">
                                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                </svg>
                                                {item.location}
                                                <span className="tooltiptext">{item.tooltipText}</span>
                                            </p>
                                            <div className="rating-wrap">
                                                <span className="rtbox">{item.rating}</span>
                                                <span className="crate" style={{ "--_score": item.ratingPercent }} />
                                                <span className="rate-text">{item.ratingsCount} Ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="caddit">
                                        <div className="item">
                                            <div className="ca-box modal-btn" data-modal="motc" data-src={item.trustCertificateUrl}>
                                                <p><i className="l3icon motc-icon" /></p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="ca-box">
                                                <p><i className="l3icon resp-icon" /></p>
                                                <p><span>Response Rate</span> <b>{item.responseRate}</b></p>
                                            </div>
                                            <p><span>Main Products</span> <b>{item.mainProducts}</b></p>
                                        </div>
                                    </div>
                                    <div className="caction">
                                        <Link to={'/register-buyer'} >
                                            <p>Contact Supplier</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </main>
        </div>
    );
}

export default RiceDealers;
